import { useContext, useState } from "react";
import { postData } from "../backend";
import { PlusCircleIcon, CalendarIcon, PencilAltIcon, TrashIcon, ChevronRightIcon, PencilIcon, AnnotationIcon, CheckIcon, UserCircleIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { Document, SignatureConfig } from "../interfaces";
import { Combobox } from "@headlessui/react";
import { WorkflowContext, useModal } from "../contexts";
import { SourceField } from "../interfaces";
import { ORDINAL_MAP, RECIPIENT_COLOUR_MAP } from "../utils";
import WorkflowSignaturesConfigModal from "./WorkflowSignaturesConfigModal";

type Field = "signature" | "date" | "text" | "checkbox";

interface Props {
  document?: Document;
  recipientNumber: number;
  signatureConfig: SignatureConfig
  updateSignatureConfig: (updates: any) => void;
}

function WorkflowSignatureConfigItem(props: Props) {
  const { document, recipientNumber, signatureConfig, updateSignatureConfig } = props;

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  const { openModal } = useModal();

  const recipientEmailKey = "recipientEmail" + recipientNumber;
  const [textColor, bgColor] = RECIPIENT_COLOUR_MAP[recipientNumber];

  // @ts-ignore
  const [email, setEmail] = useState<string>(signatureConfig[recipientEmailKey]);

  const [isOpened, setIsOpened] = useState(!!document); // Only open when there is a document present
  const [clickedField, setClickedField] = useState<Field>();

  function handleInsertSignatureField(field: Field, recipient: number) {
    if (clickedField)
      return; // Prevent action while a field is already being inserted

    setClickedField(field);
    postData(`/workflows/documents/${document!.id}/add-signature-field/`, { type: field, recipient })
      .catch(console.error)
      .finally(() => setClickedField(undefined));
  }

  function handleUpdateEmail(newEmail?: string) {
    setEmail(newEmail ?? email);
    updateSignatureConfig({ [recipientEmailKey]: newEmail ?? email });
  }

  function handleRemoveRecipient() {
    const numRecipients = Math.max(signatureConfig!.numRecipients - 1, 1);
    updateSignatureConfig({ numRecipients, [recipientEmailKey]: "" });
  }

  const filteredContacts = workflow.source?.hubspotConfig?.hubspotContactLabels.filter((label: string) => label.toLowerCase().includes(email.toLowerCase())) ?? [];
  const filteredFields = workflow.source?.sourceFields.filter((sourceField: SourceField) => {
    return sourceField.fieldName.toLowerCase().includes(email.toLowerCase().replaceAll("{", "").replaceAll("}", ""))
  }) ?? [];

  if (!workflow?.signatureConfig)
    return null

  const hubspotContactLabel = email.match(/{{__hscontact_(.+?)(?::(.+))?}}/);

  return (
    <div className="border border-gray-400 rounded">
      <div className={`flex flex-col gap-1 border-b px-2 py-1 pb-2 bg-opacity-10 rounded ${bgColor}`}>
        <div className="flex items-center w-full">
          <div className={`flex items-center cursor-pointer ${textColor}`} onClick={() => setIsOpened(f => !f)}>
            {(!!document && false) &&
              <ChevronRightIcon className="w-6 h-6 mr-2" style={{ "rotate": isOpened ? "90deg" : "0deg" }} />
            }
            <div className={`font-gilroy font-semibold text-sm`}>
              {ORDINAL_MAP[recipientNumber]} Recipient
            </div>
          </div>
          {(document && recipientNumber > 1 && recipientNumber === signatureConfig.numRecipients) &&
            <TrashIcon className="w-4 h-4 ml-auto text-gray-600 hover:text-red cursor-pointer" onClick={handleRemoveRecipient} />
          }
        </div>
        <div className="relative w-full">
          <Combobox value={email} onChange={handleUpdateEmail}>
            {hubspotContactLabel
              ? (
                <div className="border-2 rounded py-1 px-2 w-full outline-none text-sm flex items-center bg-white">
                  <UserCircleIcon className="h-4 mr-2 text-[#6e839a]" />
                  <div className="text-sm font-semibold text-gray-600">{hubspotContactLabel[2] || "Contact"}</div>
                  <Combobox.Button className="absolute right-0 top-0 bottom-0 flex items-center pr-2" onClick={() => handleUpdateEmail("")}>
                    <XIcon className="w-4 h-4 text-gray-400 cursor-pointer" />
                  </Combobox.Button>
                </div>
              ) : (
                <div className="relative">
                  <Combobox.Input className="border-2 rounded py-1 px-2 w-full outline-none text-sm" placeholder="Recipient Email" onChange={(event) => setEmail(event.target.value)} />
                  <Combobox.Button className="absolute right-0 top-0 bottom-0 flex items-center pr-2" onClick={() => handleUpdateEmail("")}>
                    {email.length === 0
                      ? <ChevronDownIcon className="w-4 h-4 text-gray-400 cursor-pointer" />
                      : <XIcon className="w-4 h-4 text-gray-400 cursor-pointer" />
                    }
                  </Combobox.Button>
                </div>
              )
            }
            <Combobox.Options className="absolute top-9 w-full z-40 flex flex-col max-h-52 bg-white border rounded shadow overflow-y-auto">
              <>
                {!!workflow.source?.hubspotConfig &&
                  <>
                    {email.length === 0 &&
                      <Combobox.Option value={`{{__hscontact_${recipientNumber}}}`} className="flex items-center text-sm text-left border-b border-gray-200 last:border-none p-2 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                        <UserCircleIcon className="h-8 mr-2 text-[#6e839a]" />
                        <div className="flex flex-col items-start">
                          <div className="text-xs font-semibold text-gray-600">HubSpot Contact</div>
                        </div>
                      </Combobox.Option>
                    }
                    {filteredContacts.map((label) => (
                      <Combobox.Option key={label} value={`{{__hscontact_${label}}}`} className="flex items-center text-sm text-left border-b border-gray-200 last:border-none p-2 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                        <UserCircleIcon className="h-8 mr-2 text-[#6e839a]" />
                        <div className="flex flex-col items-start">
                          <div className="text-xs font-semibold text-gray-400">HubSpot Contact</div>
                          <div className="flex items-center gap-1 text-sm font-semibold font-gilroy text-gray-600">
                            Labeled:
                            <div className="text-sm bg-gray-100 rounded px-2 font-semibold font-gilroy text-gray-600">
                              {label.split(":")[1]}
                            </div>
                          </div>
                        </div>
                      </Combobox.Option>
                    ))}
                  </>
                }
                {!workflow.source?.hubspotConfig &&
                  <>
                    {filteredFields.map((field) => (
                      <Combobox.Option key={field.id} value={`{{${field.fieldName}}}`} className="flex items-center justify-between text-left text-sm border-b border-gray-200 last:border-none px-2 pt-2 pb-1 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                        {field.fieldName}
                      </Combobox.Option>
                    ))}
                  </>
                }
                {email.length > 0 &&
                  <Combobox.Option value={email} className="flex items-center justify-between text-left text-sm border-b text-gray-400 border-gray-200 last:border-none px-2 pt-2 pb-1 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                    {email}
                    <div className="hidden group-first:flex text-xs bg-blue-50 roudned px-1 font-semibold font-gilroy text-gray-600">
                      Enter
                    </div>
                  </Combobox.Option>
                }
              </>
            </Combobox.Options>
          </Combobox>
        </div>
      </div>

      <div className={`flex flex-col ${document && isOpened ? "block" : "hidden"}`}>
        <div className="flex items-center text-sm border-b px-2 py-1">
          <PencilAltIcon className="w-4 h-4 mr-2" />
          <div className="font-semibold whitespace-nowrap py-2">
            Signature
          </div>
          <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "signature" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("signature", recipientNumber)}>
            {clickedField === "signature" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        </div>
        <div className="flex items-center text-sm border-b px-2 py-1">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <div className="font-semibold whitespace-nowrap py-2">
            Date
          </div>
          <div className="text-xs ml-auto flex items-start group cursor-pointer hover:text-blue hover:underline" onClick={() => openModal(<WorkflowSignaturesConfigModal {...workflowContext} />)}>
            {workflow.signatureConfig!.dateFormat || workflow.owner?.dateFormat}
            <div className="ml-1">
              {(signatureConfig.dateTimeFormat || workflow.owner?.timeFormat) === "HOUR_12" && "12Hr"}
              {(signatureConfig.dateTimeFormat || workflow.owner?.timeFormat) === "HOUR_24" && "24Hr"}
            </div>
            <PencilIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 ml-1" />
          </div>
          <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "date" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("date", recipientNumber)}>
            {clickedField === "date" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        </div>
        <div className="flex items-center text-sm border-b px-2 py-1">
          <AnnotationIcon className="w-4 h-4 mr-2" />
          <div className="font-semibold whitespace-nowrap py-2">
            Text
          </div>
          <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "text" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("text", recipientNumber)}>
            {clickedField === "text" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        </div>

        {false && // TODO: Implement requied and optional checkboxes - currently exisiting checkbox is optional
          <div className="flex items-center text-sm border-b px-2 py-1">
            <CheckIcon className="w-4 h-4 mr-2 border border-black border-dashed rounded" />
            <div className="font-semibold whitespace-nowrap py-2">
              Checkbox (Optional)
            </div>
            <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "checkbox" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("checkbox", recipientNumber)}>
              {clickedField === "checkbox" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
            </button>
          </div>
        }

        <div className="flex items-center text-sm px-2 py-1">
          <CheckIcon className="w-4 h-4 mr-2 border border-black rounded" />
          <div className="font-semibold whitespace-nowrap py-2">
            Checkbox
          </div>
          <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "checkbox" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("checkbox", recipientNumber)}>
            {clickedField === "checkbox" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        </div>
        {recipientNumber === 1 &&
          <div className="text-xs text-gray-600 py-1 px-2 border-t">
            Signable fields will be inserted at the <b>end of the document</b> and can be moved to any location.
          </div>
        }
      </div>

    </div>
  );
}

export default WorkflowSignatureConfigItem;
