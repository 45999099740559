import { useEffect } from 'react';
import { useLocation } from 'react-router';
import useUser from "../hooks/useUser";


function Intercom() {
  const { user } = useUser();
  const location = useLocation();

  // @ts-ignore
  const Intercom = window.Intercom;

  useEffect(() => Intercom('boot', {
    app_id: 'hzm8iv9k'
  }), [Intercom, user]);

  useEffect(() => {
    Intercom('update', {
      email: user?.email,
      user_hash: user?.intercomUserHash,
      last_request_at: (new Date()).getTime() / 1000,
    })
  }, [Intercom, user, location]);

  useEffect(() =>
    Intercom('update', {
      email: user?.email,
      name: user?.fullName,
      user_hash: user?.intercomUserHash,
      avatar: {
        "type": "avatar",
        "image_url": user?.avatar
      },
      platform_user: true,

      // TODO: Do we want to include the platform date joined?
      // platform_date_joined: user?.
    }), [Intercom, user]);

  return null;
}

export default Intercom;
