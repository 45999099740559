import { useHistory } from 'react-router-dom';
import { clearAuthToken } from '../auth';
import { ChevronRightIcon, CogIcon, CurrencyDollarIcon, LogoutIcon, UserIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react'
import Avatar from './Avatar';
import UsagePanel from './UsagePanel';
import GuidesButton from './GuidesButton';
import useUser from '../hooks/useUser';
import { useLayoutEffect, useMemo } from 'react';
import SegmentProgressBar from './SegmentProgressBar';
import { AchievementResponse } from '../interfaces';
import useSWR from 'swr';
import { getData, postData } from '../backend';
import { Tier, TIER_URLS } from '../utils';
import LoadingModal from './LoadingModal';
import { useModal } from '../contexts';
import ErrorModal from './ErrorModal';
import useWorkflows from '../hooks/useWorkflows';

function User() {
  const history = useHistory();

  const { openModal, closeModal } = useModal();
  const { mutate: refreshWorkflows } = useWorkflows();

  const { user, refreshUser } = useUser();
  const { data: achievementData } = useSWR<AchievementResponse>("/auth/achievements/", getData);

  const showProfileMessage = !user?.profile;
  const showSubscriptionMessage = user?.subscription?.tier === "trial";

  function handleSignOut() {
    clearAuthToken();

    // @ts-ignore
    const google = window.google;
    google.accounts.id.disableAutoSelect();

    window.location.reload();
  }

  useLayoutEffect(() => {
    refreshUser();
  }, [user, refreshUser]);

  function changeActiveTeam(teamId: string) {
    openModal(<LoadingModal title="Switching Teams..." />)

    postData("/auth/switch-team/", { team: teamId })
      .then(async user => {
        refreshUser(user, { revalidate: false });
        await refreshWorkflows();

        closeModal();
      })
      .catch(error => {
        openModal(<ErrorModal details={error} />);
      });
  }

  const CurrentTier = user?.achievementTier && user?.achievementTier in TIER_URLS
    ? {
      title: user.achievementTier,
      points: user.achievementXp,
      component: <img src={TIER_URLS[user?.achievementTier as Tier]} className="h-6 w-6 flex-shrink-0" alt={user.achievementTier} />
    }
    : {
      title: 'Initial',
      points: 0,
      component: <div className='h-6 w-6 rounded-full border-dashed border-2 border-gray-500 bg-gray-100 flex-shrink-0' />
    }

  const NextTier = useMemo(() => {
    const sortedTiers = achievementData?.tiers?.sort((tierA, tierB) => tierA.points - tierB.points) ?? [];
    const nextTierData = sortedTiers.find(tier => typeof user?.achievementXp === "number" && tier.points > user?.achievementXp);
    if (nextTierData && nextTierData.key in TIER_URLS)
      return ({
        title: nextTierData.title,
        points: nextTierData.points,
        component: <img src={TIER_URLS[nextTierData.key as Tier]} className="h-6 w-6 flex-shrink-0" alt={nextTierData.title} />
      })
    return ({ title: '', points: 0, component: <div className='h-6 w-6' /> })
  }, [user?.achievementXp, achievementData?.tiers])

  if (!user)
    return null;

  return (
    <div className='flex items-center h-full mr-8 gap-4'>
      <GuidesButton />

      <UsagePanel className="w-72" />

      <Popover className="relative h-full flex items-center gap-1">

        <Popover.Button className="outline-none rounded-full focus-visible:outline focus-visible:outline-black">
          <Avatar url={user.avatar} frame={user.achievementTier} className="w-10 h-10" />
          {showProfileMessage &&
            <div className='absolute bg-yellow text-white flex items-center justify-center border-white border-2 shadow rounded-full w-4 h-4 -right-1 -top-1' />
          }
          {!showProfileMessage && showSubscriptionMessage &&
            <div className='absolute bg-blue text-white flex items-center justify-center border-white border-2 shadow rounded-full w-4 h-4 -right-1 -top-1' />
          }
        </Popover.Button>

        <Popover.Panel className="absolute right-0 top-12 flex flex-col w-64 bg-white text-gray-600 shadow border text-sm z-40 rounded-b">
          <h4 className='font-gilroy font-semibold p-4 overflow-hidden text-ellipsis'>
            {user.email}
          </h4>

          {user.teams.length >= 1 &&
            <>
              <hr className='mx-4' />
              <div className='flex flex-col p-4 py-5 hover:bg-blue-50 relative group cursor-pointer' onClick={() => history.push("/settings/team")}>
                <div className='font-semibold text-xs font-gilroy text-gray-600'>Current Team</div>
                <div className='font-semibold text-base flex w-full justify-between'>
                  {user.activeTeam?.name}
                  {user.teams.length > 1 &&
                    <ChevronRightIcon className='w-4 h-4' />
                  }
                </div>
                {user.teams.length > 1 &&
                  <div className='absolute right-64 top-0 hidden group-hover:flex pr-4'>
                    <div className='flex flex-col w-64 bg-white text-gray-600 shadow text-sm z-40 border'>
                      <div className='font-semibold text-xs font-gilroy text-gray-600 p-4 border-b'>Switch Teams</div>
                      {user?.teams.filter(t => t.id !== user.activeTeam?.id).map(team => (
                        <div key={team.id} className='p-4 hover:bg-blue-50 cursor-pointer font-semibold' onClick={(e) => { changeActiveTeam(team.id); e.stopPropagation() }}>{team.name}</div>
                      ))}
                    </div>
                  </div>
                }
              </div>
            </>
          }

          <hr className='mx-4' />

          <Popover.Button className='flex flex-col hover:bg-blue-50 cursor-pointer p-4' onClick={() => history.push("/settings")}>
            <div className='flex items-center gap-2 font-gilroy'>
              <UserIcon className='w-4 h-4' />
              Profile
            </div>
            {showProfileMessage &&
              <div className='text-xs font-semibold text-yellow text-left mt-2 -mb-2'>
                Your profile is not yet complete
              </div>
            }
          </Popover.Button>

          <Popover.Button className='flex flex-col hover:bg-blue-50 cursor-pointer p-4' onClick={() => history.push("/settings/billing")}>
            <div className='flex items-center gap-2 font-gilroy'>
              <CurrencyDollarIcon className='w-4 h-4' />
              Subscription
            </div>
            {showSubscriptionMessage &&
              <div className='text-xs font-semibold text-blue text-left mt-2 -mb-2'>
                You are currently on a trial of Pro
              </div>
            }
          </Popover.Button>

          <Popover.Button className='flex items-center gap-2 hover:bg-blue-50 cursor-pointer p-4 font-gilroy' onClick={() => history.push("/settings")}>
            <CogIcon className='w-4 h-4' />
            Settings
          </Popover.Button>

          <Popover.Button className='flex items-center gap-2 hover:bg-blue-50 cursor-pointer p-4 font-gilroy' onClick={handleSignOut}>
            <LogoutIcon className='w-4 h-4' />
            Sign Out
          </Popover.Button>

          <hr className='mx-4 my-1' />

          <Popover.Button
            className='flex flex-row gap-2 hover:bg-blue-50 cursor-pointer p-4 font-gilroy items-center'
            onClick={() => history.push("/settings/achievements")}
          >
            {CurrentTier.component}
            <div className='flex flex-col w-full items-start'>
              <b className='capitalize'>Current Tier: {CurrentTier.title}</b>
              <SegmentProgressBar
                fillPercentage={user.achievementXp * 100 / NextTier.points}
                className='justify-center'
              />
              <p>{user?.achievementXp} {NextTier.points ? `/ ${NextTier.points}` : ''}</p>
            </div>
            {NextTier.component}
          </Popover.Button>

        </Popover.Panel>

      </Popover>
    </div>
  );
}

export default User;
