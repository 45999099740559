import { useEffect, useState } from "react";
import { getData, postData } from "../backend";
import { HubspotAppData, WorkflowAutomation, Document } from "../interfaces";
import AppHubspotObjects from "./AppHubspotObjects";
import AppHubspotContacts from "./AppHubspotContacts";
import { BLOCK_ICON_MAP } from "../utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { HubspotAppContext, HubspotPage } from "../contexts";
import AppHubspotButton from "./AppHubspotButton";
import AppHubspotAutomation from "./AppHubspotAutomation";
import packageJson from '../../package.json';

function AppHubspot() {
  const queryParams = new URLSearchParams(window.location.search);
  const payload = queryParams.get('payload');

  const [pickerData, setPickerData] = useState<HubspotAppData | null>();
  const [selectedObjects, setSelectedObjects] = useState<Record<string, boolean>>({});
  const [selectedContacts, setSelectedContacts] = useState<Record<string, string>>({});
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);

  const [error, setError] = useState<string>();
  const [isStarting, setIsStarting] = useState<boolean>(false);
  const [currentAutomation, setCurrentAutomation] = useState<WorkflowAutomation>();

  const [currentPage, setCurrentPage] = useState<HubspotPage>("ASSOCIATED_OBJECTS");

  const allSectedContactsValid = Object.values(selectedContacts).every((email) => email.match(/(.+@.+\..+)|(\{\{.+\}\})/));

  useEffect(() => {
    if (pickerData !== undefined)
      return;

    getData(`/hubspot/associated-objects/${payload}/`)
      .then((data: HubspotAppData) => {
        setPickerData(data);

        setSelectedObjects(data.associatedObjectOptions.reduce((acc: Record<string, boolean>, objList: any) => {
          objList.items.forEach((obj: any) => acc[`${objList.toId}.${obj.id}`] = true);
          return acc;
        }, {} as Record<string, boolean>));
      })
      .catch(() => setPickerData(null));
  }, [payload, pickerData]);

  function handleStartAutomation() {
    if (pickerData?.signatureRequestNumRecipients && currentPage === "ASSOCIATED_OBJECTS")
      return setCurrentPage("SIGNATURE_EMAILS");

    setIsStarting(true);

    const associatedObjectIds = (() => {
      if (selectedObjects === undefined)
        return undefined;

      return Object.entries(selectedObjects)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
    })()

    postData(`/hubspot/associated-objects/${payload}/`, { associatedObjectIds, selectedContacts })
      .then((automation) => setCurrentAutomation(automation))
      .then(() => setCurrentPage("AUTOMATION"))
      .catch(setError)
      .finally(() => setIsStarting(false));
  }

  if (!pickerData) {
    return (
      <div className="relative h-screen w-screen flex">
        <div className="flex flex-col items-center m-auto">
          <img src="https://static.portant.co/portant-loading-gray.svg" alt="Loading Logo" className='w-32 h-32' />
          <div className="text-gray-300 font-semibold text-center text-sm mt-2">Loading associated objects</div>
          <div className="w-[400px] mt-20">
            <AppHubspotButton text="Quick Start Automation with All Objects" btnClass="btn-white" handleStartAutomation={handleStartAutomation} />
          </div>
        </div>
        <div className='absolute left-0 bottom-0 text-xs font-semibold text-gray-400'>v{packageJson.version}</div>
      </div>
    );
  }

  if (currentPage === "AUTOMATION" && currentAutomation) {
    return <AppHubspotAutomation automation={currentAutomation} />
  }

  const workflow = pickerData.workflow;
  const currentDocument: Document | undefined = workflow.documents[currentDocumentIndex];

  return (
    <HubspotAppContext.Provider value={{ pickerData, currentAutomation, currentPage, setCurrentPage, isStarting, error }}>
      <div className="flex w-screen gap-8 px-2 h-screen justify-center">
        {!!currentDocument &&
          <div className="w-[600px] flex flex-col">
            <div className="flex w-full items-center mb-2">
              <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
                <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[currentDocument?.documentType]} alt="Google File Icon" />
              </div>
              <div className="flex flex-col h-full justify-center">
                <div className="flex items-center text-gray-500 text-sm font-gilroy font-semibold">
                  Template Preview
                </div>
                <div className="flex text-sm font-semibold font-gilroy">
                  <div className="truncate max-w-[400px]">
                    {currentDocument?.file?.name}
                  </div>
                </div>
              </div>
              {workflow.documents.length > 1 &&
                <div className="flex items-center ml-auto">
                  <ChevronLeftIcon className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => setCurrentDocumentIndex(i => Math.max(0, i - 1))} />
                  <div className="font-semibold text-gray-600 font-gilroy w-12 text-center">
                    {currentDocumentIndex + 1} / {workflow.documents.length}
                  </div>
                  <ChevronRightIcon className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => setCurrentDocumentIndex(i => Math.min(i + 1, workflow.documents.length - 1))} />
                </div>
              }
            </div>
            {currentDocument?.file?.url
              ? <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${currentDocument.file?.url.replace("/edit", "/preview")}?widget&rm=minimal`} />
              : <div className="m-auto text-sm font-semibold text-gray-400">Unable to load document preview</div>
            }
          </div>
        }
        <div className="flex flex-col w-[400px] flex-shrink-0">
          {currentPage === "ASSOCIATED_OBJECTS" &&
            <AppHubspotObjects selectedObjects={selectedObjects} setSelectedObjects={setSelectedObjects} />
          }

          {currentPage === "SIGNATURE_EMAILS" &&
            <AppHubspotContacts selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} />
          }

          <AppHubspotButton text="Start Automation" btnClass="btn-blue" handleStartAutomation={handleStartAutomation} disabled={currentPage === "SIGNATURE_EMAILS" && !allSectedContactsValid} />
        </div>
      </div>
    </HubspotAppContext.Provider>
  )
}

export default AppHubspot;
