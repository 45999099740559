import { useState } from "react";
import { UserGroupIcon, CalendarIcon, DocumentTextIcon, LightningBoltIcon, ExclamationIcon } from '@heroicons/react/outline';
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, capitalize } from "../utils";
import HeroIcon from "./HeroIcon";
import moment from 'moment';
import { Workflow } from "../interfaces";
import WorkflowListItemOptions from "./WorkflowListItemOptions";
import { KeyedMutator } from "swr";
import { useHistory } from "react-router-dom";

interface Props {
  workflow: Workflow;
  mutateWorkflowList: KeyedMutator<any>;
}

function WorkflowListCard(props: Props) {
  const { workflow, mutateWorkflowList } = props;
  const history = useHistory();

  const [showPreview, setShowPreview] = useState<boolean>(true);

  return (
    <button
      className="relative flex flex-col border border-gray-200 shadow rounded overflow-hidden cursor-pointer hover:scale-105 transition-transform h-64"
      onClick={() => history.push(`/w/${workflow.id}`)}
    >

      <div className="relative w-full h-40 bg-gray-200 py-4 px-8 border-b border-gray-200 overflow-hidden">
        <div className="absolute flex items-center justify-center w-full h-full top-0 left-0"><LightningBoltIcon className="w-20 h-20 text-gray-300" /></div>
        {(showPreview && workflow.previewFileId) &&
          <img className="relative w-full rounded shadow" onError={() => setShowPreview(false)} src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${workflow.previewFileId}`} alt="Thumbnail" />
        }
      </div>

      <div className="absolute top-2 right-2">
        <WorkflowListItemOptions workflow={workflow} mutateWorkflowList={mutateWorkflowList} />
      </div>

      <div className="flex flex-col gap-4 p-4 font-gilroy my-auto">
        <div className="flex items-center cursor-pointer w-full justify-center">
          <div className="flex items-center justify-center w-4 h-4 mr-2 text-white rounded flex-shrink-0" style={{ backgroundColor: workflow.color }}>
            <HeroIcon icon={workflow.icon} className="w-3 h-3" />
          </div>
          <div className="font-gilroy font-semibold text-gray-600 overflow-ellipsis overflow-hidden whitespace-nowrap">
            {workflow.name}
          </div>
          {workflow.team && <UserGroupIcon className="ml-2 h-4 w-4 text-gray-600" />}
        </div>

        <div className="flex items-center gap-2 text-xs font-gilroy text-gray-600">
          {workflow.previewSourceType
            ? <>
              <div className="group relative flex items-center gap-1">
                <img className="w-4 h-4" src={BLOCK_ICON_MAP[workflow.previewSourceType]} alt="Source icon" />
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Source: {BLOCK_NAME_MAP[workflow.previewSourceType]}
                </div>
              </div>

              <div className="w-px h-4 bg-gray-400" />

              <div className="group relative flex items-center gap-1">
                <DocumentTextIcon className="w-4 h-4" />
                {workflow.itemsTotal}
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Total usage
                </div>
              </div>

              <div className="group relative flex items-center gap-1">
                <CalendarIcon className="w-4 h-4" />
                {workflow.itemsPeriod}
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Usage this period
                </div>
              </div>
            </>
            : <>
              <div className="group relative flex items-center gap-1 text-yellow font-semibold flex-shrink-0">
                <ExclamationIcon className="w-4 h-4" />
                Add source
              </div>
            </>
          }

          <div className="ml-auto flex-shrink-0">
            {capitalize(moment(workflow.updatedAt).fromNow())}
          </div>
        </div>
      </div>
    </button>
  );
}

export default WorkflowListCard;

