import { Transition } from "@headlessui/react";
import { ClipboardCopyIcon, DocumentTextIcon, ExternalLinkIcon, FilterIcon, LinkIcon, PencilAltIcon, PencilIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { OAuthError, putData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import WorkflowFormResponseList from "./WorkflowFormResponseList";
import WorkflowSelectModal from "./WorkflowSelectModalAdd";
import GooglePickerButton from "./GooglePickerButton";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { useHistory, useRouteMatch } from "react-router-dom";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import useUser from "../hooks/useUser";


function WorkflowSourceForms() {
  const { openModal } = useModal();

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;
  const source = workflow.source;

  const history = useHistory();
  const { url } = useRouteMatch();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [formLinkIsCopied, setFormLinkIsCopied] = useState<boolean>(false);

  function handleCopyFormLink() {
    setFormLinkIsCopied(true);
    setTimeout(() => setFormLinkIsCopied(false), 1000);
  }

  async function handleChangeFile(file: any) {
    try {
      setIsRefreshing(true);
      await putData(`/workflows/sources/${source!.id}/file/`, { file });
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }

    await mutateWorkflow();
    setIsRefreshing(false);
  }

  if (!source)
    return null;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;
  const responserFormUrl = `https://docs.google.com/forms/d/${source.file?.formId}/viewform`;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions && <>
            <div className="flex flex-col text-gray-600">
              <button className="flex items-center btn btn-white border" onClick={() => window.open(source.file?.url.replace("/viewform", "/edit"), "_blank")}>
                <PencilAltIcon className="w-4 h-4 mr-2" /> Edit Form
              </button>
              <div className="text-xs mt-2 text-center">
                Please click refresh (<RefreshIcon className="inline-block w-3 h-3" />) after making changes to your form to see the latest source fields in Portant.
              </div>
            </div>

            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} showRefreshSpinning={isRefreshing} disableCopy />

            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-1">
                <div className="flex items-center font-gilroy font-semibold text-sm">
                  <LinkIcon className="w-4 h-4 mr-2" /> Form Preview Link
                </div>
                <div className="flex items-center gap-2">
                  <input type="text" className="w-full text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2" value={responserFormUrl} onClick={e => e.currentTarget.select()} readOnly />
                  <CopyToClipboard text={responserFormUrl!} onCopy={handleCopyFormLink}>
                    <button className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm">
                      <ClipboardCopyIcon className="h-4 w-4 group-hover:text-blue" />
                      <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
                        {formLinkIsCopied ? "Copied" : "Copy to clipboard"}
                      </div>
                    </button>
                  </CopyToClipboard>
                  <a className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm" href={responserFormUrl} target="_blank" rel="noreferrer">
                    <ExternalLinkIcon className="h-4 w-4 group-hover:text-blue" />
                    <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
                      Open in new tab
                    </div>
                  </a>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center font-gilroy font-semibold text-gray-600">
                    <FilterIcon className="w-6 h-6 mr-1" /> Source Filter Conditions
                  </div>
                  <div className="font-semibold text-xs text-gray-600">
                    Only process data that meets certain conditions
                  </div>
                </div>
                {(source!.sourceConditions.length > 0) &&
                  <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600">
                    <div>Filtering by <span className="font-semibold">{source!.sourceConditions.length}</span> condition{source!.sourceConditions.length === 1 ? "" : "s"}</div>
                  </div>
                }
                <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                  {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
                </button>
              </div>
            </div>
          </>}

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Template" subtitle="Create or select a template to merge your data with" showDocuments {...workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[source.sourceType]} alt="Google File Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(source.file?.url, "_blank")}>
              <div className="truncate max-w-[400px]">
                {source.file?.name}
              </div>
              {source.file?.url &&
                <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
              }
            </div>
            {hasPermission(workflow.team, "workflows.edit") &&
              <GooglePickerButton className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600" blockType={source.sourceType} onPicked={handleChangeFile} disabled={!hasPermission(workflow.team, "workflows.edit")}>
                <PencilIcon className="h-4 mr-1" /><>Change selected file</>
              </GooglePickerButton>
            }
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>
        <WorkflowFormResponseList className="w-full h-full border border-gray-300 rounded overflow-hidden" />
      </Transition.Child>

    </>
  );

}

export default WorkflowSourceForms;
