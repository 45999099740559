import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon, DatabaseIcon, DocumentTextIcon, ExclamationCircleIcon, ExclamationIcon, PencilIcon, SparklesIcon, XIcon } from "@heroicons/react/outline";
import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { OAuthError, deleteData, patchData, postData, putData } from "../backend";
import { WorkflowContext, useModal, LastFocusedEditorContext } from "../contexts";
import { BLOCK_ICON_MAP, DATE_FORMAT_MAP, MIME_TYPE_ICON_MAP, RECIPIENT_COLOUR_MAP, TIME_FORMAT_MAP, capitalize, luminance } from "../utils";
import useUser from "../hooks/useUser";
import { SignatureConfig, Attachment, AttachmentType } from "../interfaces";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { TemplatedTextEditorRef } from "./TemplatedTextEditor";
import WorkflowBlockModalSave from './WorkflowModalSave';
import WorkflowSelectModal from './WorkflowSelectModalAdd';
import OAuthModal from './OAuthModal';
import WorkflowSignatureConfigItem from './WorkflowSignatureConfigItem';
import EmailAliasPopover from './EmailAliasPopover';
import TeamsLabel from './TeamsLabel';
import SmallTextInputField from './SmallTextInputField';
import PayWallModalTeams from './PayWallModalTeams';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SmallSelectField from './SmallSelectField';
import EmailEditor, { EmailEditorRef } from './EmailEditor';
import WorkflowEmailToolbarButton from './WorkflowEmailToolbarButton';
import WorkflowModalImage from './WorkflowModalImage';
import EmailModalAttachment from './EmailModalAttachment';
import ProLabel from './ProLabel';
import WorkflowModalLink from './WorkflowModalLink';
import PayWallModalPro from './PayWallModalPro';
import ToggleButton from './ToggleButton';
import moment from 'moment-timezone';
import OAuthModalMicrosoft from './OAuthModalMicrosoft';
import WorkflowSelectModalAdd from './WorkflowSelectModalAdd';
import TransitionWorkflowPage from './transitions/TransitionWorkflowPage';
import SettingsTeamModalEdit from './SettingsTeamModalEdit';

type SaveState = "INITIAL" | "HAS_CHANGES" | "SAVING" | "SAVED";

function WorkflowSignatureRequest() {
  const { user, hasPermission } = useUser();

  const history = useHistory();
  const { pathname } = useLocation();

  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;

  const allowEditing = hasPermission(workflow.team, "workflows.edit");

  const [signatureConfig, setSignatureConfig] = useState(workflow.signatureConfig);

  const editorSubject = useRef<TemplatedTextEditorRef>(null);
  const editorContent = useRef<TemplatedTextEditorRef>(null);

  // TODO: Eventually replace every field in "signatureConfig" for this new variable
  // The issue with signatureConfig is that it updates the back instantaneously (with a post) without waiting for the user to *save* the changes
  // The current fields are a bit complex so I'd rather replace them bit by bit to prevent breaking everything
  const [emailConfig, setEmailConfig] = useState({
    emailContent: workflow.signatureConfig?.emailContent,
    emailButtonText: workflow.signatureConfig?.emailButtonText,
    emailFromAlias: workflow.signatureConfig?.emailFromAlias,
    emailSubject: workflow.signatureConfig?.emailSubject
  })

  const richEditorContent = useRef<EmailEditorRef>(null);
  const richEditorContentAfter = useRef<EmailEditorRef>(null);

  const [showFormattingMenu, setShowFormattingMenu] = useState(false);
  const formattingMenuContainer = useRef<HTMLDivElement | null>(null);

  const [sharedEditorContent, setSharedEditorContent] = useState<string>(workflow.signatureConfig?.emailContent ?? "");
  const [lastFocusedEditor, setLastFocusedEditor] = useState<RefObject<TemplatedTextEditorRef> | null>(editorSubject);

  const [saveState, setSaveState] = useState<SaveState>("INITIAL");

  const [hasCheckedScopes, setHasCheckedScopes] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState(workflow.signatureConfig?.redirectUrl ?? "");
  const [redirectUrlInvalid, setRedirectUrlInvalid] = useState(false);

  const [addingAttachment, setAddingAttachment] = useState<boolean>(false);
  const [removingAttachmentId, setRemovingAttachmentId] = useState<string>();
  const [updatingAttachmentId, setUpdatingAttachmentId] = useState<string>();

  const customFormattingEnabled = user?.subscription?.featureSignatures && signatureConfig?.customFormatting;
  const isWorkflowOwner = (workflow.owner?.id === user?.id);

  const { openModal, closeModal } = useModal();

  useEffect(() => {
    setSignatureConfig(workflow.signatureConfig);
    setEmailConfig(prev => ({ ...prev, emailButtonText: workflow.signatureConfig?.emailButtonText }))
  }, [workflow.signatureConfig]);

  useEffect(() => {
    if (hasCheckedScopes || !pathname.endsWith("/signature-request"))
      return;

    postData("/auth/scopes/", { scopes: ["https://www.googleapis.com/auth/gmail.send"] })
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal title='Portant requires your permission to send emails' oauthUrl={error.oauthUrl} />);
      })
      .finally(() => setHasCheckedScopes(true));
  }, [hasCheckedScopes, openModal, pathname]);

  function handleUpdateEmail({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (!(target.name in emailConfig)) return;

    setEmailConfig(prev => ({ ...prev, [target.name]: target.value }));
    setSaveState("HAS_CHANGES");
  }

  function handleEmailFrom(emailFromAlias: string | null) {
    setEmailConfig(prev => ({ ...prev, emailFromAlias }));
    setSaveState("HAS_CHANGES");
  }

  function updateCustomFormating(customFormatting: boolean) {
    updateSignatureConfig({ customFormatting });

    if (customFormatting)
      setSharedEditorContent(editorContent.current!.getValue());
    else
      setSharedEditorContent(richEditorContent.current!.getTextValue());

    setShowFormattingMenu(false);
  }

  async function updateSignatureConfig(updates: any) {
    if (!signatureConfig)
      return;

    if (updates.emailType === "OUTLOOK") {
      postData("/microsoft/access-token/")
        .catch(error => {
          updateSignatureConfig({ emailType: "GMAIL" })
          if (error instanceof OAuthError) {
            openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} onClose={() => { updateSignatureConfig({ emailType: "OUTLOOK" }) }} />);
          }
        })
    }

    const updatedSignatureConfig = { ...signatureConfig!, ...updates };
    setSignatureConfig(updatedSignatureConfig);

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/`, updatedSignatureConfig)
      .catch(console.error);

    mutateWorkflow();
  }

  async function updateSendFromOwner(sendFromOwner: boolean) {
    if (!signatureConfig)
      return;

    let updatedSignatureConfig = { ...signatureConfig!, sendFromOwner };
    setSignatureConfig(updatedSignatureConfig);

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/owner-update/`, updatedSignatureConfig)
      .catch(console.error);

    mutateWorkflow();
  }

  async function handleSave() {
    let updates: Record<string, string> = {
      emailSubject: emailConfig.emailSubject ?? "",
    }

    if (customFormattingEnabled) {
      updates["emailContent"] = richEditorContent.current?.getValue() ?? "";
      updates["emailContentAfter"] = richEditorContentAfter.current?.getValue() ?? "";
    } else {
      updates["emailContent"] = emailConfig.emailContent ?? "";
    }

    if (emailConfig.emailFromAlias !== workflow.signatureConfig?.emailFromAlias) {
      updates.emailFromAlias = emailConfig.emailFromAlias as string;
    }

    if (emailConfig.emailButtonText !== undefined && emailConfig.emailButtonText !== signatureConfig?.emailButtonText)
      updates["emailButtonText"] = emailConfig.emailButtonText;

    let updatedSignatureConfig: SignatureConfig = {
      ...signatureConfig!, ...updates
    }

    setSaveState("SAVING");

    await putData(`/signatures/signature-configs/${signatureConfig!.id}/`, updatedSignatureConfig);
    await mutateWorkflow();

    setSaveState("SAVED");

    // Store shared editor content to display if the block is opened again
    setSharedEditorContent(updates["emailContent"]);
  }

  function handleAttemptClose() {
    if (saveState === "HAS_CHANGES")
      return openModal(<WorkflowBlockModalSave onSave={() => handleSave().then(() => history.push(url))} onDontSave={() => history.push(url)} />);

    history.push(url);
  }

  function handleOpenCustomBranding() {
    if (user?.subscription?.tier !== "teams")
      return openModal(<PayWallModalTeams />);

    openModal(<SettingsTeamModalEdit />);
  }

  function handleSaveRedirectUrl() {
    if (user?.subscription?.tier !== "teams") {
      setRedirectUrlInvalid(true);
      return openModal(<PayWallModalTeams />);
    }

    updateSignatureConfig({ redirectUrl });
  }

  function handleEditSignatureRecipients() {
    history.push(url + "/document#signable");
  }

  function addAttachment(attachmentType: AttachmentType, payload: any) {
    closeModal();
    setAddingAttachment(true);

    let options: Record<string, string> = {};
    if (attachmentType === "INSERT_OUTPUT_AS_LINK")
      options["sharingMode"] = "VIEWER";

    postData(`/signatures/signature-configs/${signatureConfig?.id}/attachments/`, { attachmentType, ...payload })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setAddingAttachment(false));
  }

  function removeAttachment(attachmentId: string) {
    closeModal();
    setRemovingAttachmentId(attachmentId);

    deleteData(`/signatures/signature-config-attachments/${attachmentId}/`)
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setRemovingAttachmentId(undefined));
  }

  function updateAttachment(attachmentId: string, sharingMode: string) {
    setUpdatingAttachmentId(attachmentId);

    patchData(`/signatures/signature-config-attachments/${attachmentId}/`, { sharingMode })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setUpdatingAttachmentId(undefined));
  }

  function toggleFormattingMenu() {
    if (!user?.subscription?.featureSignatures)
      return openModal(<PayWallModalPro />);

    if (!customFormattingEnabled)
      updateCustomFormating(true);

    if (!lastFocusedEditor?.current || !lastFocusedEditor.current?.allowFormatting())
      richEditorContent.current?.focus()

    setShowFormattingMenu(f => !f);
  }

  function toggleAttachmentMenu() {
    setShowFormattingMenu(false);

    if (!user?.subscription?.featureSignatures)
      return openModal(<PayWallModalPro />);

    if (!customFormattingEnabled)
      updateCustomFormating(true);

    openModal(<EmailModalAttachment workflow={workflow} attachments={signatureConfig!.attachments} addAttachment={addAttachment} removeAttachment={removeAttachment} />)
  }

  function toggleLinkMenu() {
    setShowFormattingMenu(false);

    if (!user?.subscription?.featureSignatures)
      return openModal(<PayWallModalPro />);

    if (!customFormattingEnabled)
      updateCustomFormating(true);

    if (richEditorContent.current)
      openModal(<WorkflowModalLink editor={richEditorContent.current.getEditor()} />)
  }

  function toggleImageMenu() {
    setShowFormattingMenu(false);

    if (!user?.subscription?.featureSignatures)
      return openModal(<PayWallModalPro />);

    if (!customFormattingEnabled)
      updateCustomFormating(true);

    if (richEditorContent.current)
      openModal(<WorkflowModalImage editor={richEditorContent.current.getEditor()} />)
  }

  async function updateExpiryDuration(expiryDurationEnabled: boolean) {
    if (expiryDurationEnabled)
      updateSignatureConfig({ expiryDuration: "30 00:00:00" });
    else
      updateSignatureConfig({ expiryDuration: null });
  }

  async function updategeneratePreviewRequest(generatePreviewRequest: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ generatePreviewRequest })
  }

  async function updateEnableTracking(enableTracking: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ enableTracking })
  }

  async function updateEnableAuditTrail(enableAuditTrail: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ enableAuditTrail })
  }

  if (!signatureConfig) {
    if (!pathname.endsWith("/signature-request"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow does not contain a signature request, would you like to add one?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModalAdd title="Add Workflow Signature Request" subtitle="Add a signature request to your workflow" showSignatureRequest {...workflowContext} />)}>
          Add Signature Request
        </button>
      </div>
    );
  }

  const hubspotContactLabel1 = signatureConfig.recipientEmail1.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel2 = signatureConfig.recipientEmail2.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel3 = signatureConfig.recipientEmail3.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel4 = signatureConfig.recipientEmail4.match(/{{__hscontact_(.+?)(?::(.+))?}}/);

  return (
    <LastFocusedEditorContext.Provider value={{ editorRef: lastFocusedEditor, setEditorRef: setLastFocusedEditor }}>
      <TransitionWorkflowPage show={pathname.endsWith("/signature-request")}>

        {allowEditing &&
          <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
            enter="transition ease-in-out duration-150 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-150 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >

            {!workflow.source && <>
              <div className="flex flex-col gap-4 justify-center h-full">
                <div className="flex items-center">
                  <DatabaseIcon className="w-6 h-6 mr-2" />
                  <div className="font-gilroy font-semibold text-lg">
                    Connect a Source
                  </div>
                </div>
                <div className="flex flex-col gap-2 font-semibold text-gray-600">
                  Create a batch of documents from a spreadsheet
                  <div className="flex items-center gap-2">
                    <div className="h-px bg-gray-600 w-full" />
                    <div className="flex-shrink-0">or</div>
                    <div className="h-px bg-gray-600 w-full" />
                  </div>
                  Auto-Create documents from an event or form&nbsp;submission
                </div>
                <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Source" subtitle="Create or select a source where your data will come from" showSources {...workflowContext} />)}>
                  Connect
                </button>
              </div>
            </>}

            {workflow.source && <>
              <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} />
              <hr />

              {workflow.documents.filter(d => d.isSignable).length === 0 &&
                <div className='flex items-center gap-1 text-yellow font-semibold text-sm bg-yellow/20 border-2 border-yellow p-1 rounded'>
                  <ExclamationIcon className='w-8 h-8 flex-shrink-0' /> Please select the documents in this workflow to link to this signature request
                </div>
              }

              {workflow.documents.length > 0 &&
                <>
                  <div className='flex flex-col gap-2'>
                    <div className='flex items-center justify-between'>
                      <div className='font-gilroy font-semibold text-gray-600 text-sm'>
                        Signature Request Recipients
                      </div>
                      {workflow.documents.filter(d => d.isSignable).length > 0 &&
                        <div className='text-xs font-gilroy font-semibold text-gray-400 hover:text-blue cursor-pointer' onClick={handleEditSignatureRecipients}>
                          Add/Remove
                        </div>
                      }
                    </div>
                    {[...Array(signatureConfig.numRecipients)].map((_, index: number) =>
                      <WorkflowSignatureConfigItem key={index} recipientNumber={index + 1} signatureConfig={signatureConfig} updateSignatureConfig={updateSignatureConfig} />
                    )}
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                      <div className='text-sm font-semibold font-gilroy text-gray-600'>
                        Expire Signature Requests
                      </div>
                    </div>
                    <div className='flex items-start gap-2'>
                      <ToggleButton value={!!signatureConfig.expiryDuration} onChange={updateExpiryDuration} />
                      <div className='text-xs font-semibold text-gray-400'>
                        Have signature&nbsp;requests expire after<br /> 30 days of being sent
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='text-sm font-semibold font-gilroy text-gray-600'>
                      Email Tracking
                    </div>
                    <div className='flex items-start gap-2'>
                      <ToggleButton value={signatureConfig.enableTracking} onChange={updateEnableTracking} />
                      <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                        View when each signature request email is opened
                      </div>
                      <ProLabel />
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='text-sm font-semibold font-gilroy text-gray-600 flex gap-2'>
                      Audit Trail
                      <div className="flex justify-center items-center bg-gray-100 w-14 rounded-sm shadow flex-shrink-0">
                        <div className="font-gilroy font-semibold text-black text-sm mr-1">New</div>
                        <SparklesIcon className="h-4 w-4 text-yellow" />
                      </div>
                    </div>
                    <div className='flex items-start gap-2'>
                      <ToggleButton value={signatureConfig.enableAuditTrail} onChange={updateEnableAuditTrail} />
                      <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                        Attach an audit trail to the signed document to show the document history
                      </div>
                      <ProLabel />
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='text-sm font-semibold font-gilroy text-gray-600 flex gap-2'>
                      Generate Preview
                      <div className="flex justify-center items-center bg-gray-100 w-14 rounded-sm shadow flex-shrink-0">
                        <div className="font-gilroy font-semibold text-black text-sm mr-1">New</div>
                        <SparklesIcon className="h-4 w-4 text-yellow" />
                      </div>
                    </div>
                    <div className='flex items-start gap-2'>
                      <ToggleButton value={!!signatureConfig.generatePreviewRequest} onChange={updategeneratePreviewRequest} />
                      <div className='text-xs font-semibold text-gray-400'>
                        Generate a preview of the document before requesting signatures. Link can be accessed from the outputs page {workflow.source.sourceType === "HUBSPOT" ? "and in HubSpot." : "."}
                      </div>
                    </div>
                  </div>

                  {(workflow.team && false) && // Currently disabled
                    <div className='flex flex-col gap-1'>
                      <div className='text-sm font-semibold font-gilroy text-gray-600'>
                        Send from Workflow Owner
                      </div>

                      <div className='flex flex-col'>
                        <div className='flex items-center gap-2'>
                          <ToggleButton value={signatureConfig!.sendFromOwner} onChange={updateSendFromOwner} disabled={!isWorkflowOwner} />
                          <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                            Always send emails from the workflow owner
                          </div>
                        </div>
                        {!isWorkflowOwner &&
                          <div className='text-xs font-semibold text-gray-600 w-full text-center mt-1'>
                            This settings can only be configured by the workflow&nbsp;owner: <b>{workflow.owner?.fullName}</b>
                          </div>
                        }
                      </div>
                    </div>
                  }

                  <div className='flex flex-col'>
                    <div className='font-gilroy font-semibold text-gray-600 text-sm'>
                      Date Field Format
                    </div>
                    <div className='text-xs font-semibold text-gray-400'>
                      Control the format in which the automatic date is added to signed documents when using a date field
                    </div>
                    <div className="flex gap-2 mt-1">
                      <SmallSelectField className='flex-1' label="Date Format" value={signatureConfig.dateFormat} onChange={(dateFormat: string) => updateSignatureConfig({ dateFormat })}>
                        {Object.entries(DATE_FORMAT_MAP)
                          .map(([value, label]) => {
                            if (value === workflow.owner?.dateFormat) // Default
                              return <option key={value} value={""}>{label} (Default)</option>;

                            return <option key={value} value={value}>{label}</option>;
                          })}
                      </SmallSelectField>
                      <SmallSelectField className="flex-1" label="Time Format" value={signatureConfig.dateTimeFormat} onChange={(dateTimeFormat: string) => updateSignatureConfig({ dateTimeFormat })}>
                        {Object.entries(TIME_FORMAT_MAP)
                          .map(([value, label]) => {
                            if (value === workflow.owner?.timeFormat) // Default
                              return <option key={value} value={""}>{label} (Default)</option>;

                            return <option key={value} value={value}>{label}</option>;
                          })}
                      </SmallSelectField>
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                      <div className='text-sm font-semibold font-gilroy text-gray-600'>
                        Custom Confirmation Page
                      </div>
                      <TeamsLabel />
                    </div>
                    <div className='text-xs font-semibold text-gray-400'>
                      When a recipient has completed a signature request redirect them automatically to your own webpage
                    </div>
                    <SmallTextInputField value={redirectUrl} onChange={value => { setRedirectUrl(value); setRedirectUrlInvalid(false); }} onEnter={handleSaveRedirectUrl} label='URL' placeholder='https://www.example.com/thankyou' invalid={redirectUrlInvalid} />
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                      <div className='text-sm font-semibold font-gilroy text-gray-600'>
                        Custom eSign Portal Branding
                      </div>
                      <TeamsLabel />
                    </div>
                    <div className='text-xs font-semibold text-gray-400'>
                      Control the appearance of the eSign portal recipients will visit when to complete your signature request
                    </div>
                    <button className='text-sm btn btn-white py-1' onClick={handleOpenCustomBranding} >Customise Branding</button>
                  </div>
                </>
              }

              {workflow.documents.length === 0 && <>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center font-gilroy font-semibold text-gray-600">
                      <DocumentTextIcon className="w-6 h-6 mr-1" /> Add document
                    </div>
                    <div className="font-semibold text-xs text-gray-400">
                      Add a templated document to be included with this email
                    </div>
                  </div>
                  <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add document" subtitle="Add a templated document to be included with this email" showDocuments {...workflowContext} />)}>
                    Add
                  </button>
                </div>
              </>}

            </>}
          </Transition.Child>
        }

        <Transition.Child className="flex flex-col bg-white w-[800px] m-auto border border-gray-200 shadow rounded overflow-hidden"
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='bg-gray-100 flex items-center gap-2 px-2 py-1 border-b border-gray-200'>
            <img className="h-4 w-4" src={BLOCK_ICON_MAP[signatureConfig.emailType]} alt="Email Icon" />
            <div className='font-gilroy font-semibold text-gray-600 text-sm flex items-center'>
              Request Signatures via&nbsp;
              {hasPermission(workflow.team, "workflows.edit") &&
                <Popover className="relative">
                  <Popover.Button className='flex items-center underline'>
                    {capitalize(signatureConfig.emailType)} <ChevronDownIcon className='w-4 h-4' />
                  </Popover.Button>
                  <Popover.Panel className="absolute w-28 left-0 top-6 flex flex-col bg-white text-gray-600 drop-shadow-lg rounded text-sm z-40">
                    <Popover.Button className='font-gilroy font-semibold p-2 hover:bg-gray-50 cursor-pointer whitespace-nowrap text-left flex items-center gap-1' onClick={() => updateSignatureConfig({ emailType: "GMAIL" })}>
                      <img className="w-4 flex-shrink-0" src={BLOCK_ICON_MAP["GMAIL"]} alt="Gmail Icon" /> Gmail
                    </Popover.Button>
                    <Popover.Button className='font-gilroy font-semibold p-2 hover:bg-gray-50 cursor-pointer whitespace-nowrap text-left flex items-center gap-1' onClick={() => updateSignatureConfig({ emailType: "OUTLOOK" })}>
                      <img className="w-4 flex-shrink-0" src={BLOCK_ICON_MAP["OUTLOOK"]} alt="Outlook Icon" /> Outlook
                    </Popover.Button>
                  </Popover.Panel>
                </Popover>
              }
              {!hasPermission(workflow.team, "workflows.edit") &&
                <>{capitalize(signatureConfig.emailType)}</>

              }
            </div>
            <XIcon className="w-5 h-5 ml-auto text-gray-600 hover:text-red cursor-pointer" onClick={handleAttemptClose} />
          </div>

          <div className="flex flex-col h-full p-2 flex-shrink-0">
            <div className="flex items-center w-full relative">
              <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-from">From</label>
              {signatureConfig.emailType === "GMAIL"
                ? (
                  <EmailAliasPopover
                    value={emailConfig.emailFromAlias ?? ''}
                    setValue={handleEmailFrom}
                    workflow={workflow}
                    sendFromOwner={signatureConfig.sendFromOwner}
                    aliasDisplay={signatureConfig.aliasDisplay}
                    allowEditing={allowEditing}
                  />
                ) : (
                  <div>{user!.microsoftCredentials?.microsoftEmailAddress ?? "Your Microsoft Outlook Account"}</div>
                )
              }
            </div>

            <div className="flex items-center w-full relative my-1 flex-shrink-0">
              <label className="w-20 font-gilroy font-medium text-gray-500 flex-shrink-0" htmlFor="email-to">To</label>
              <div className='flex flex-col w-full -mr-2'>
                <div className="flex gap-2 items-center w-full relative mt-2 mb-2 select-none overflow-x-auto  pr-2 scrollbar-hidden">
                  {signatureConfig.generatePreviewRequest &&
                    <div className='flex items-center gap-2 flex-shrink-0'>
                      <div className={`bg-gray-600 relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                        Requested via Preview
                      </div>
                      <ChevronRightIcon className='w-4 h-4 text-gray-400' />
                    </div>
                  }
                  <div className={`${RECIPIENT_COLOUR_MAP[1][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                    1. {hubspotContactLabel1
                      ? hubspotContactLabel1[2] || "Contact"
                      : signatureConfig.recipientEmail1 || "Email Required"
                    }
                    {!signatureConfig.recipientEmail1 &&
                      <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                    }
                  </div>
                  {signatureConfig.numRecipients >= 2 &&
                    <div className='flex items-center gap-2 flex-shrink-0'>
                      {!signatureConfig.generatePreviewRequest &&
                        <ChevronRightIcon className='w-4 h-4 text-gray-400' />
                      }
                      <div className={`${RECIPIENT_COLOUR_MAP[2][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                        2. {hubspotContactLabel2
                          ? hubspotContactLabel2[2] || "Contact"
                          : signatureConfig.recipientEmail2 || "Email Required"
                        }
                        {!signatureConfig.recipientEmail2 &&
                          <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                        }
                      </div>
                    </div>
                  }
                  {signatureConfig.numRecipients >= 3 &&
                    <div className='flex items-center gap-2 flex-shrink-0'>
                      {!signatureConfig.generatePreviewRequest &&
                        <ChevronRightIcon className='w-4 h-4 text-gray-400' />
                      }
                      <div className={`${RECIPIENT_COLOUR_MAP[3][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                        3. {hubspotContactLabel3
                          ? hubspotContactLabel3[2] || "Contact"
                          : signatureConfig.recipientEmail3 || "Email Required"
                        }
                        {!signatureConfig.recipientEmail3 &&
                          <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                        }
                      </div>
                    </div>
                  }
                  {signatureConfig.numRecipients >= 4 &&
                    <div className='flex items-center gap-2 flex-shrink-0'>
                      {!signatureConfig.generatePreviewRequest &&
                        <ChevronRightIcon className='w-4 h-4 text-gray-400' />
                      }
                      <div className={`${RECIPIENT_COLOUR_MAP[4][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                        4. {hubspotContactLabel4
                          ? hubspotContactLabel4[2] || "Contact"
                          : signatureConfig.recipientEmail4 || "Email Required"
                        }
                        {!signatureConfig.recipientEmail4 &&
                          <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                        }
                      </div>
                    </div>
                  }
                </div>
                {(signatureConfig.numRecipients >= 2) &&
                  <div className='text-gray-600 text-xs font-semibold'>
                    {signatureConfig.generatePreviewRequest
                      ? "Emails are sent in any order as selected in the preview document"
                      : "Emails are sent in sequence, after the first is completed the second will be sent and so on"
                    }
                  </div>
                }
              </div>
            </div>

            <hr className="border-gray-400" />

            <div className="flex items-center w-full mt-2">
              <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-subject" onClick={() => editorSubject.current?.focus()}>Subject</label>
              <input
                value={emailConfig.emailSubject}
                onChange={handleUpdateEmail}
                name="emailSubject"
                className='w-full focus-visible:outline-none h-10 whitespace-nowrap overflow-hidden ml-2'
              />
            </div>
            {emailConfig.emailSubject?.length === 0 &&
              <div className="flex text-yellow text-sm items-center mb-1" onClick={() => editorSubject.current?.focus()}>
                <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                Please provide a subject
              </div>
            }

            <hr className="border-gray-400 pb-2" />

            <div className="flex flex-col h-[400px]">

              {!customFormattingEnabled &&
                <div className='flex flex-col bg-gray-100 h-full w-full p-5 overflow-y-auto'>
                  <div className='bg-white w-full h-min flex flex-col gap-2 p-5 rounded flex-shrink-0'>
                    <div className='font-gilroy text-lg font-semibold'>
                      Signature Request
                    </div>
                    <div className=''>
                      {user!.fullName} has requested you sign the following documents.
                    </div>
                    <div className={allowEditing ? "border border-dashed rounded -m-1 p-1 border-gray-400 relative" : ""}>
                      <textarea
                        value={emailConfig.emailContent}
                        className='w-full min-h-[80px] resize-none focus-visible:outline-none'
                        name="emailContent"
                        onChange={handleUpdateEmail}
                      />
                      {allowEditing &&
                        <PencilIcon className='w-5 h-5 absolute right-1 bottom-1 text-gray-400' />
                      }
                    </div>
                    <div className='relative btn p-0 text-sm my-2 rounded blue bg-blue text-white group whitespace-nowrap cursor-text w-min' style={{ backgroundColor: workflow.team?.brandingColour, color: luminance(workflow.team?.brandingColour ?? "black") >= 0.5 ? "black" : "white" }}>
                      <input
                        className='px-[20px] py-[10px] min-w-[200px] bg-blue outline-none text-center rounded'
                        value={emailConfig.emailButtonText}
                        name='emailButtonText'
                        onChange={handleUpdateEmail}
                        disabled={!allowEditing}
                      />
                      <div className='invisible group-hover:visible absolute left-4 top-10 tooltip rounded-tl-none'>
                        This button will take the recipient of the email to the signable document
                      </div>
                    </div>
                    <div className='h-px bg-gray-400' />
                    <div className='text-gray-400 text-sm font-semibold'>
                      ⚠️ Warning: To prevent others from accessing your document, please do not forward this email.
                    </div>
                  </div>
                  <div className='text-xs mt-2'>
                    ©{moment().year()} <a className='text-blue underline' href="https://www.portant.co" target='blanke'>Portant</a>
                  </div>
                </div>
              }

              {customFormattingEnabled &&
                <div className="relative w-full h-full overflow-y-auto p-5 bg-gray-100">
                  <div className='bg-white p-5 rounded'>
                    <EmailEditor
                      ref={richEditorContent}
                      className="min-h-[150px]"
                      initialValue={sharedEditorContent ?? ""}
                      showFormattingMenu={lastFocusedEditor === richEditorContent ? showFormattingMenu : false}
                      formattingMenuContainer={formattingMenuContainer}
                      onChange={() => setSaveState("HAS_CHANGES")}
                      onFocus={() => setLastFocusedEditor(richEditorContent)}
                      onDoubleDown={() => richEditorContentAfter.current?.focus()}
                      disabled={!allowEditing}
                    />
                    <div className='relative btn p-0 text-sm my-2 rounded blue bg-blue text-white group whitespace-nowrap cursor-text w-min' style={{ backgroundColor: workflow.team?.brandingColour, color: luminance(workflow.team?.brandingColour ?? "black") >= 0.5 ? "black" : "white" }}>
                      <input
                        className='px-[20px] py-[10px] min-w-[200px] bg-blue outline-none text-center rounded'
                        value={emailConfig.emailButtonText}
                        name='emailButtonText'
                        onChange={handleUpdateEmail}
                        disabled={!allowEditing}
                      />
                      <div className='invisible group-hover:visible absolute left-4 top-10 tooltip rounded-tl-none'>
                        This button will take the recipient of the email to the signable document
                      </div>
                    </div>
                    <EmailEditor
                      ref={richEditorContentAfter}
                      initialValue={signatureConfig.emailContentAfter}
                      showFormattingMenu={lastFocusedEditor === richEditorContentAfter ? showFormattingMenu : false}
                      formattingMenuContainer={formattingMenuContainer}
                      onChange={() => setSaveState("HAS_CHANGES")}
                      onFocus={() => setLastFocusedEditor(richEditorContentAfter)}
                      onDoubleUp={() => richEditorContent.current?.focus()}
                      disabled={!allowEditing}
                    />
                  </div>
                </div>
              }

              <div className='mt-auto flex-shrink-0 flex flex-col pt-2'>
                {addingAttachment &&
                  <div className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
                    <div className='w-4 h-4 mr-2 flex-shrink-0' />
                    <div className={`text-gray-600 text-sm font-semibold truncate w-96`}>
                      Adding attachment...
                    </div>
                  </div>
                }

                {signatureConfig.attachments.map((attachment: Attachment) =>
                  <div key={attachment.id} className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
                    <img className='w-4 h-4 mr-2 flex-shrink-0' src={MIME_TYPE_ICON_MAP[attachment.attachmentMimeType ?? "application/pdf"] ?? BLOCK_ICON_MAP["GOOGLE_DRIVE"]} alt="Attachment icon" />
                    <div className={`text-gray-600 text-sm font-semibold truncate w-96 ${removingAttachmentId === attachment.id ? "line-through" : ""}`} onClick={() => attachment.driveFile && window.open(attachment.driveFile?.url)}>
                      {attachment.attachmentName}
                    </div>
                    {attachment.attachmentType === "INSERT_OUTPUT_AS_LINK" &&
                      <div className='text-xs mr-2'>
                        <select value={attachment.sharingMode} className='font-gilroy font-semibold bg-gray-50 shadow rounded py-px' onChange={e => updateAttachment(attachment.id, e.currentTarget.value)} disabled={updatingAttachmentId === attachment.id}>
                          <option value="VIEWER">Viewer</option>
                          <option value="COMMENTER">Commenter</option>
                          <option value="EDITOR">Editor</option>
                        </select>
                      </div>
                    }
                    <XIcon className="w-5 h-5 text-gray-400 hover:text-gray-600 ml-auto cursor-pointer flex-shrink-0" onClick={() => removeAttachment(attachment.id)} />
                  </div>
                )}
              </div>

              {allowEditing &&

                <div className="flex flex-col flex-shrink-0">
                  <div ref={formattingMenuContainer} />

                  <div className="my-1 flex items-center z-10 flex-shrink-0 w-full">
                    <div className='flex items-center min-w-min'>
                      <div className='flex mx-2 h-full gap-2 items-center'>
                        <WorkflowEmailToolbarButton isActive={showFormattingMenu} iconName="format_color_text" tooltipText="Formatting options" onClick={toggleFormattingMenu} />
                        <WorkflowEmailToolbarButton iconName="attach_file" tooltipText="Attach files" onClick={toggleAttachmentMenu} />
                        <WorkflowEmailToolbarButton iconName="link" tooltipText="Insert link" onClick={toggleLinkMenu} />
                        <WorkflowEmailToolbarButton iconName="insert_photo" tooltipText="Insert photo" onClick={toggleImageMenu} />
                        <div className='h-[20px] w-px bg-gray-400 mx-2' />
                      </div>

                      {!user?.subscription?.featureSignatures &&
                        <>
                          <ProLabel />
                          <div className='text-xs text-gray-400 font-gilroy font-semibold mx-2 w-60 mr-auto'>
                            Pro users may also add content after the customisable signature request button
                          </div>
                        </>
                      }

                      {user?.subscription?.featureSignatures &&
                        <>
                          <ToggleButton value={signatureConfig.customFormatting} onChange={updateCustomFormating} />
                          <div className='text-xs text-gray-600 font-gilroy font-semibold mx-2 w-60'>
                            Custom formatting
                          </div>
                        </>
                      }
                    </div>

                    {(saveState === "INITIAL") &&
                      <button className=" ml-auto btn btn-blue w-32" disabled>
                        Save
                      </button>
                    }
                    {(saveState === "HAS_CHANGES") &&
                      <button className=" ml-auto btn btn-blue w-32" onClick={handleSave}>
                        Save
                      </button>
                    }
                    {(saveState === "SAVING") &&
                      <button className=" ml-auto btn btn-blue w-32" disabled>
                        Saving...
                      </button>
                    }
                    {(saveState === "SAVED") &&
                      <button className=" ml-auto btn btn-green w-32">
                        Saved
                      </button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>

        </Transition.Child>
      </TransitionWorkflowPage>
    </LastFocusedEditorContext.Provider>
  );

}

export default WorkflowSignatureRequest;
