import { useEffect, useState } from "react";
import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import useScript from "../hooks/useScript";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, GOOGLE_MIMETYPE_MAP } from "../utils";
import ErrorModal from "./ErrorModal";
import OAuthModal from "./OAuthModal";
import WorkflowModalEdit from "./WorkflowModalEdit";
import { BasicWorkflow, Workflow } from "../interfaces";
import { DocumentDuplicateIcon, PlusIcon } from "@heroicons/react/outline";

interface Props {
  blockType: string;
  title: string;
  subtitle: string;
  openBlock: string | null;
  setOpenBlock: (s: string) => void;
  onSelection: (blockType: string, file?: any) => void;
  isNewWorkflow?: boolean;
}

const scopes = ["https://www.googleapis.com/auth/drive"];

function WorkflowSelectSheetsTemplateButton(props: Props) {
  const { openModal } = useModal();

  const status = useScript('https://apis.google.com/js/api.js');
  const [pickerLoaded, setPickerLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (status !== "ready")
      return;

    // @ts-ignore
    const gapi = window.gapi;

    gapi.load('picker',
      () => setPickerLoaded(true)
    );
  }, [status]);

  async function handleOpenPicker() {
    function handlePickerSelection(pickerResult: any) {

      const createWorkflowFromFile = (basicInfo?: BasicWorkflow) => {
        props.onSelection(
          props.blockType,
          basicInfo ? { ...pickerResult.docs[0], ...basicInfo } : pickerResult.docs[0]
        )
      };

      if (pickerResult.action === 'picked') {
        if (props.isNewWorkflow)
          basicInfoNewFile({ name: pickerResult.docs[0].name }, createWorkflowFromFile);
        else
          createWorkflowFromFile();
      }
    }

    try {
      const accessToken = await postData("/auth/access-token/", { scopes });

      // @ts-ignore
      const google = window.google;

      const picker = new google.picker.PickerBuilder()
        .setAppId(process.env.REACT_APP_GOOGLE_APP_ID)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .setOAuthToken(accessToken)
        .setSize(document.body.clientWidth, document.body.clientHeight)
        .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
        .setCallback(handlePickerSelection)

      const docsView = new google.picker.DocsView(google.picker.ViewId["SPREADSHEETS"])
      docsView.setMode(google.picker.DocsViewMode.LIST)
      picker.addView(docsView);

      const rootView = new google.picker.DocsView()
      rootView.setMode(google.picker.DocsViewMode.LIST)
      rootView.setMimeTypes(GOOGLE_MIMETYPE_MAP[props.blockType])
      rootView.setIncludeFolders(true)
      rootView.setParent("root")
      picker.addView(rootView);

      const sharedView = new google.picker.DocsView()
      sharedView.setMode(google.picker.DocsViewMode.LIST)
      sharedView.setMimeTypes(GOOGLE_MIMETYPE_MAP[props.blockType])
      sharedView.setIncludeFolders(true)
      sharedView.setEnableDrives(true)
      picker.addView(sharedView);

      picker.build()
        .setVisible(true);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={handleOpenPicker} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  function basicInfoNewFile(initialFile?: BasicWorkflow, createWorkflowFunction?: (newWorkflow?: BasicWorkflow) => void) {
    const workflow = {
      name: initialFile?.name ? `${initialFile.name} Workflow` : 'New Workflow',
      color: initialFile?.color ?? "#cccccc",
      icon: initialFile?.icon ?? 'DocumentText'
    } as Workflow

    const onUpdateWorkflow = (newWorkflow: BasicWorkflow) => {
      if (createWorkflowFunction) {
        createWorkflowFunction(newWorkflow);
      } else {
        handleCreateNewFile(newWorkflow);
      }
    }

    openModal(
      <WorkflowModalEdit
        workflow={workflow}
        updateWorkflow={onUpdateWorkflow}
        acceptText="Create"
        titleText="Create Workflow"
      />
    );
  }

  async function handleCreateNewFile(newWorkflow?: BasicWorkflow) {
    try {
      await postData("/auth/access-token/", { scopes });
      props.onSelection(props.blockType, newWorkflow);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={handleCreateNewFile} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  if (props.openBlock === props.blockType)
    return (
      <div className="flex flex-shrink-0 items-center border-2 border-gray-200 rounded">
        <button
          className="group flex items-center w-full border-2 border-[#00000000] hover:border-blue rounded cursor-pointer h-full px-8 py-4 -m-0.5"
          onClick={handleOpenPicker}
          disabled={!pickerLoaded}
        >
          <img className="w-12 h-12" src={BLOCK_ICON_MAP["GOOGLE_SHEETS"]} alt={BLOCK_NAME_MAP["GOOGLE_SHEETS"]} />
          <div className="text-sm group-disabled:text-gray-400 group-disabled:cursor-progress font-gilroy font-semibold text-gray-600 ml-4">
            Select Google Sheets file
          </div>
        </button>
        <button
          className="flex items-center w-full border-2 border-[#00000000] hover:border-blue rounded cursor-pointer h-full px-8 py-4 -m-0.5"
          onClick={() => props.isNewWorkflow ? basicInfoNewFile() : handleCreateNewFile()}
        >
          <img className="w-12 h-12" src="https://static.portant.co/drive-new-icon.svg" alt="New File Icon" />
          <div className="text-sm font-gilroy font-semibold text-gray-600 ml-4">
            New Google Sheets file
          </div>
        </button>
      </div>
    );

  return (
    <button className="flex flex-shrink-0 items-center border-2 border-gray-200 hover:border-blue rounded cursor-pointer px-8 py-4" onClick={() => props.setOpenBlock(props.blockType)}>
      <div className="relative">
        <img className="w-12 h-12" src={BLOCK_ICON_MAP["GOOGLE_SHEETS"]} alt={BLOCK_NAME_MAP["GOOGLE_SHEETS"]} />
        {props.blockType === "GOOGLE_SHEETS_MERGE" &&
          <div className="absolute -top-2 -right-1 bg-white shadow rounded border text-xs p-0.5">
            <DocumentDuplicateIcon className="w-5 h-5 " />
          </div>
        }
        {props.blockType === "GOOGLE_SHEETS_APPEND" &&
          <div className="absolute -top-2 -right-1 bg-white shadow rounded border text-xs p-1">
            <PlusIcon className="w-4 h-4 " />
          </div>
        }
      </div>
      <div className="flex flex-col items-start ml-4">
        <div className="font-gilroy font-semibold text-lg text-gray-600">
          {props.title}
        </div>
        <div className="font-gilroy font-semibold text-sm text-gray-400">
          {props.subtitle}
        </div>
      </div>
    </button>
  );
}

export default WorkflowSelectSheetsTemplateButton;
