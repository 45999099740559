import { useState } from 'react';
import { useModal } from '../contexts';
import TextInputField from './TextInputField';
import IconPickerField from './IconPickerField';
import ColorPickerField from './ColorPickerField';
import Modal from './Modal';
import { BasicWorkflow, Workflow } from '../interfaces';

interface Props {
  updateWorkflow: ((workflow: Workflow) => void) | ((workflow: BasicWorkflow) => void),
  workflow: Workflow,
  acceptText?: string,
  titleText?: string,
}

function WorkflowModalEdit(props: Props) {
  const [workflow, setWorkflow] = useState<Workflow>(props.workflow);
  const { closeModal } = useModal();

  const handleSubmit = () => {
    props.updateWorkflow(workflow);
  }

  return (
    <Modal
      title={props.titleText ?? "Update Workflow"}
      primaryButtonProps={{
        text: props.acceptText ?? 'Update',
        onClick: () => { props.updateWorkflow(workflow); closeModal(); }
      }}
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
    >
      <TextInputField label="Workflow Name" value={workflow.name} onChange={(name) => setWorkflow((w: any) => ({ ...w, name }))} required onEnter={handleSubmit} />
      <div className="mt-4 mb-1 font-gilroy font-semibold">
        Appearance
      </div>
      <div className="flex gap-16">
        <ColorPickerField value={workflow.color} onChange={(color) => setWorkflow((w: any) => ({ ...workflow, color }))} />
        <div className='flex flex-grow gap-4'>
          <IconPickerField value={workflow.icon} onChange={(icon) => setWorkflow((w: any) => ({ ...workflow, icon }))} selectColour={workflow.color} />
        </div>
      </div>
    </Modal>
  );
}

export default WorkflowModalEdit;
