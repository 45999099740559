import { Transition } from "@headlessui/react";

function TransitionWorkflowPageContent(props: { children: React.ReactNode }) {
  return (
    <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {props.children}
    </Transition.Child>
  );
}

export default TransitionWorkflowPageContent;
