import { XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { patchData } from "../backend";
import { WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowOverviewBlockModal from "./WorkflowOverviewBlockModal";
import useUser from "../hooks/useUser";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function WorkflowOverviewBlockWriteToSourceModal(props: Props) {
  const [isSaving, setIsSaving] = useState(false);

  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const documents = workflow.documents;
  const [writeToSource, setWriteToSource] = useState<string[]>(workflow.source?.config.writeToSource ?? []);

  function handleCheckOnChange(value: boolean, documentId: string) {
    if (value)
      setWriteToSource(ids => [...ids, documentId])
    else
      setWriteToSource(ids => ids.filter(id => id !== documentId))
  }

  async function handleSave() {
    setIsSaving(true);

    await patchData(`/workflows/sources/${workflow.source!.id}/`, { config: { ...workflow.source?.config, writeToSource } })
      .then(() => mutateWorkflow())
      .catch(console.error);

    setIsSaving(false);
    props.setOpen(false);
  }

  return (
    <WorkflowOverviewBlockModal open={props.open} setOpen={props.setOpen} >
      <div className="relative flex flex-col gap-6 w-[620px]">
        <XIcon className="absolute w-6 h-6 -top-4 -right-4 cursor-pointer text-gray-400 hover:text-gray-600" type="button" onClick={() => props.setOpen(false)} />

        <div className="flex items-center gap-2">
          <img className="h-10 w-10" src={BLOCK_ICON_MAP["GOOGLE_SHEETS"]} alt="Google Sheets Icon" />
          <div className="flex flex-col">
            <div className="font-gilroy font-semibold">
              Write Links to Source
            </div>
            <div className="text-gray-600 text-sm">Append Document Links to the End of the Corresponding Source Row</div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-gray-600 text-sm font-semibold">
            {documents.length > 0
              ? "Include links for the following outputs:"
              : "This workflow does not contain any template documents"
            }
          </div>
          {documents.map(document => (
            <>
              {!document.removeOutput &&
                <label key={document.id} className="flex items-center gap-2 cursor-pointer p-1 -mx-1 hover:bg-blue hover:bg-opacity-10 rounded-sm">
                  <input type="checkbox" checked={!!writeToSource.find(id => id === document.id)} onChange={e => hasPermission(workflow.team, "workflows.edit") && handleCheckOnChange(e.currentTarget.checked, document.id)} />
                  <img className='w-6 h-6' src={BLOCK_ICON_MAP[document.documentType]} alt="Attachment icon" />
                  <div className="text-sm font-gilroy font-semibold select-none">{document.file?.name}</div>
                </label>
              }
              {document.createPdfCopy &&
                <label key={`${document.id}-pdf`} className="flex items-center gap-2 cursor-pointer p-1 -m-1 hover:bg-blue hover:bg-opacity-10 rounded-sm">
                  <input type="checkbox" checked={!!writeToSource.find(id => id === document.id + "-pdf")} onChange={e => handleCheckOnChange(e.currentTarget.checked, document.id + "-pdf")} />
                  <img className='w-6 h-6' src={BLOCK_ICON_MAP["PDF"]} alt="Attachment icon" />
                  <div className="text-sm font-gilroy font-semibold select-none">{document.file?.name} (PDF)</div>
                </label>
              }
            </>
          ))}
        </div>
        <div className="flex justify-between items-center">
          <div className="text-xs text-gray-600 mr-8">
            Portant will create new columns in your spreadsheet, the column headers can be renamed but must contain <span className="font-mono bg-gray-200 inline-block p-px rounded">[Portant]</span> to be reused in future automations.
          </div>
          {hasPermission(workflow.team, "workflows.edit") && documents.length > 0 &&
            <button className="btn btn-blue text-sm w-48" onClick={handleSave} type="button" disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</button>
          }
        </div>
      </div>
    </WorkflowOverviewBlockModal>
  );
}

export default WorkflowOverviewBlockWriteToSourceModal;
