import React, { useState, useContext, createContext, Dispatch, RefObject, SetStateAction, useEffect } from "react";
import { AchievementResponse, HubspotAppData, User, Workflow, WorkflowAutomation } from "./interfaces";
import { HTMLTextEditorRef, TemplatedTextEditorRef } from "./components/TemplatedTextEditor";
import useSWR, { KeyedMutator } from "swr";
import { useHistory, useLocation } from "react-router-dom";
import UnsavedContentModal from "./components/UnsavedContentModal";
import useUser from "./hooks/useUser";
import { getData } from "./backend";

// Modal

interface ModalContextData {
  openModal: (comp: JSX.Element) => void;
  closeModal: (onClose?: () => void) => void;
}

export const ModalContext = React.createContext<ModalContextData>({
  openModal: () => { },
  closeModal: () => { }
});

export function ModalProvider(props: { children: React.ReactNode }) {
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const openModal = (el: JSX.Element) => setModal(el);
  const closeModal = (onClose?: () => void) => { setModal(null); if (onClose) onClose(); };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <div className="relative">
        {props.children}
        <div className={`fixed inset-0 z-50 pointer-events-none bg-black/30 transition-opacity ${modal ? 'opacity-100' : 'opacity-0'}`} tabIndex={-1} />
        {modal}
      </div>
    </ModalContext.Provider>
  );
}

export const useModal: () => ModalContextData = () => {
  return useContext(ModalContext);
};

// Workflow

export type WorkflowView = "OVERVIEW" | "SOURCE" | "BLOCK" | "OUTPUTS" | "PREVIEW" | "SIGNATURE_REQUEST";
export interface WorkflowBlock {
  blockType: string;
  blockId: string;
}

export interface WorkflowContextData {
  workflow: Workflow;
  mutateWorkflow: KeyedMutator<Workflow>;
  updateAutoCreate: (value: boolean) => void;
  automationOptions: any;
  updateAutomationOptions: (options: any) => void;
  showAutomationPanel: boolean;
  setShowAutomationPanel: (f: boolean) => void;
}

export const WorkflowContext = createContext<WorkflowContextData>({
  workflow: {} as Workflow,
  mutateWorkflow: {} as KeyedMutator<Workflow>,
  updateAutoCreate: () => { },
  automationOptions: {},
  updateAutomationOptions: () => { },
  showAutomationPanel: false,
  setShowAutomationPanel: () => { },
});

interface WorkflowOnlyContextData {
  workflow?: Workflow;
}

export const WorkflowOnlyContext = createContext<WorkflowOnlyContextData>({});

// Backend

interface BackendContextData {
  version?: string | null;
}

export const BackendContext = createContext<BackendContextData>({});

interface LastFocusedEditorData {
  editorRef: RefObject<TemplatedTextEditorRef | HTMLTextEditorRef> | null;
  setEditorRef: Dispatch<SetStateAction<RefObject<any> | null>>;
};

export const LastFocusedEditorContext = createContext<LastFocusedEditorData>({
  editorRef: null,
  setEditorRef: () => { }
});

type WorkflowListView = 'ROWS' | 'CARDS';
type WorkflowFilter = 'ALL' | 'PERSONAL' | 'TEAM';

type WorkflowData = {
  workflowUrl: string;
  workflowFilter: WorkflowFilter;
  workflowSearch: string;
  workflowShowSearch: boolean;
  workflowListView: WorkflowListView;
}

type TemplateData = {
  templateCategory: string,
  templateUrl: string,
}

type SettingsData = {
  settingsUrl: string
}
interface SectionsData {
  workflow: WorkflowData;
  template: TemplateData;
  settings: SettingsData;
  unsavedChanges: boolean;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  updateWorkflowData: (property: keyof WorkflowData, value: string | boolean) => void;
  updateTemplateData: (property: keyof TemplateData, value: string) => void;
  updateSettingsData: (property: keyof SettingsData, value: string) => void;
}

export const SectionsContext = createContext<SectionsData>({
  workflow: {
    workflowUrl: '',
    workflowFilter: 'ALL',
    workflowListView: 'ROWS',
    workflowSearch: '',
    workflowShowSearch: false,
  },
  unsavedChanges: false,
  template: {
    templateCategory: '',
    templateUrl: '',
  },
  settings: {
    settingsUrl: ''
  },
  setUnsavedChanges: () => { },
  updateSettingsData: () => { },
  updateWorkflowData: () => { },
  updateTemplateData: () => { },
})

export function SectionsProvider(props: { children: React.ReactNode }) {
  const history = useHistory();

  const location = useLocation();
  const pathname = location.pathname;

  const user = useUser();
  const [olderUser, setOlderUser] = useState<User>();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [pendingRedirect, setPendingRedirect] = useState<string | null>(null)
  const [pendingNotification, setPendingNotification] = useState<{ title: String; description: String }>();
  const [displayNotification, setDisplayNotification] = useState(false);

  const { openModal } = useModal();
  const { data: achievementdata } = useSWR<AchievementResponse>("/auth/achievements/", getData);

  const [workflow, setWorkflow] = useState<WorkflowData>({
    workflowUrl: '',
    workflowFilter: 'ALL',
    workflowListView: localStorage.getItem("workflowListView") as WorkflowListView ?? "CARDS",
    workflowSearch: '',
    workflowShowSearch: false,
  });

  const [template, setTemplate] = useState<TemplateData>({
    templateCategory: '',
    templateUrl: '/templates',
  });

  const [settings, setSettings] = useState<SettingsData>({
    settingsUrl: '/settings',
  });

  const updateWorkflowData = (property: keyof WorkflowData, newValue: string | boolean) => {
    setWorkflow(prev => ({ ...prev, [property]: newValue }))
  }

  const updateTemplateData = (property: keyof TemplateData, newValue: string) => {
    setTemplate(prev => ({ ...prev, [property]: newValue }))
  }

  const updateSettingsData = (property: keyof SettingsData, newValue: string) => {
    setSettings(prev => ({ ...prev, [property]: newValue }))
  }

  useEffect(() => {
    if (!olderUser && user.user)
      setOlderUser(user.user);
    else if (olderUser && olderUser.achievementXp !== user.user?.achievementXp && achievementdata?.achievements) {
      setOlderUser(user.user as User)
      const oldAchievementKeys = olderUser?.achievements?.map(ach => ach.key) ?? [];
      const newAchievementKey = user.user?.achievements?.find(ac => !oldAchievementKeys.includes(ac.key))?.key
      const newAchievement = achievementdata.achievements.find(ac => ac.key === newAchievementKey);
      if (newAchievement?.key) {
        setDisplayNotification(true);
        setPendingNotification({ title: 'New achievement unlocked!', description: `+${newAchievement.points} - ${newAchievement.title}` });
        setTimeout(() => setDisplayNotification(false), 3000);
      }
    }
  }, [user, olderUser, achievementdata]);

  useEffect(() => {
    if (!displayNotification && pendingNotification) {
      setTimeout(() => setPendingNotification(undefined), 3000);
    }
  }, [displayNotification, pendingNotification]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (unsavedChanges) {
        openModal(
          <UnsavedContentModal onConfirm={() => {
            setUnsavedChanges(false);
            setPendingRedirect(location.pathname)
            unblock();
          }} />)
        return false;
      }
    });

    return () => {
      setPendingRedirect(null);
      unblock();
    };
  }, [unsavedChanges, history, openModal]);

  useEffect(() => {
    if (pendingRedirect !== null && !unsavedChanges) {
      history.push(pendingRedirect);
      setPendingRedirect(null)
    }
  }, [pendingRedirect, unsavedChanges, history]);

  useEffect(() => {
    localStorage.setItem("workflowListView", workflow.workflowListView);
  }, [workflow.workflowListView])

  useEffect(() => {
    if (pathname?.includes('settings')) {
      setSettings(prev => ({ ...prev, settingsUrl: pathname }));
    } else if (pathname?.includes('template')) {
      setTemplate(prev => ({ ...prev, templateUrl: pathname }));
    } else if (pathname === '/' || pathname?.includes('/w/')) {
      setWorkflow(prev => ({ ...prev, workflowUrl: pathname }));
    }
  }, [pathname]);

  return (
    <SectionsContext.Provider value={{
      workflow,
      template,
      settings,
      unsavedChanges,
      setUnsavedChanges,
      updateWorkflowData,
      updateTemplateData,
      updateSettingsData
    }}>
      {props.children}
      {/* NOTE: Notifications have been removed for the time being */}
      {/* <div className="relative">
        <div className="absolute top-32 right-4 z-10">
          <Transition 
            show={false && displayNotification}
            enter="transition ease duration-700 transform"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-[50vh]"
          >
            <div className="text-white bg-blue-30 p-2 rounded-lg">
              <p className="font-gray-600 font-semibold">{pendingNotification?.title}</p>
              <span>{pendingNotification?.description}</span>
            </div>
          </Transition>
        </div>
      </div> */}
    </SectionsContext.Provider>
  );
}

// HubSpot Picker

export type HubspotPage = "ASSOCIATED_OBJECTS" | "SIGNATURE_EMAILS" | "AUTOMATION";

export interface HubspotAppContextData {
  pickerData: HubspotAppData;
  currentPage: string;
  setCurrentPage: Dispatch<SetStateAction<HubspotPage>>;
  isStarting: boolean;
  currentAutomation?: WorkflowAutomation;
  error?: string;
}

export const HubspotAppContext = createContext<HubspotAppContextData>({} as HubspotAppContextData);
