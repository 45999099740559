import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { OAuthError, getData, postData } from "../backend";
import { useModal } from "../contexts";
import { TeamUser, Workflow } from "../interfaces";
import useUser from "../hooks/useUser";
import Modal from "./Modal";
import OAuthModal from "./OAuthModal";
import HeroIcon from "./HeroIcon";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Tooltip from "./Tooltip";
import useWorkflows from "../hooks/useWorkflows";
import Paginator from "./Paginator";


type Access = "CHECKING" | "VALID" | "INVALID" | "PARTIAL" | "PENDING" | "GRANTING" | "ERROR";
type AccessMapping = {
  [workflowId: string]: {
    status: "PENDING" | "CHECKING" | "ERROR" | "CHECKED" | "UPDATING" | "UPDATED",
    access?: { [userId: string]: Access }
  }
}

function SettingsTeamUserModalAccessBatch() {
  const { openModal, closeModal } = useModal();

  const { user } = useUser();
  const team = user?.activeTeam!;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { data, isValidating } = useWorkflows({ workflowType: "TEAM" }, currentPageNumber);
  const { results: teamWorkflows, numPages } = data;
  const enabledPrevious = currentPageNumber > 1;
  const enabledNext = currentPageNumber < numPages;

  const [isCheckinggAccess, setIsCheckingAccess] = useState(false);
  const [isGrantingAccess, setIsGrantingAccess] = useState(false);

  const [hasCheckedSinceUpdate, setHasCheckedSinceUpdate] = useState(false);

  const [userAccess, setUserAccess] = useState<AccessMapping>();

  const accessSummary = useMemo(() => {
    const ret: { [workflowId: string]: { totalEntries: number, validEntries: number, valid: boolean } } = {};

    for (const [workflowId, { status, access }] of Object.entries((userAccess ?? {}))) {
      if (status !== "CHECKED")
        continue;

      const entries = Object.entries(access!)
      const validEntries = entries.filter(([_, access]) => access === "VALID").length;

      ret[workflowId] = {
        totalEntries: entries.length, validEntries, valid: validEntries === entries.length
      }
    }

    return ret;
  }, [userAccess]);

  const nonValidWorkflowIds = Object.entries(accessSummary).filter(([_, { valid }]) => !valid).map(([workflowId, _]) => workflowId);

  const checkUserAccess = useCallback(async (workflowId: string) => {
    return await getData(`/workflows/${workflowId}/check-access/`);
  }, []);

  const checkAccessToAll = useCallback(async () => {
    if (!teamWorkflows?.length || isCheckinggAccess) return;

    setIsCheckingAccess(true);

    const updatedAccess: AccessMapping = {};

    for (const workflow of teamWorkflows)
      updatedAccess[workflow.id] = { status: "PENDING" };
    setUserAccess(updatedAccess);


    for (const workflow of teamWorkflows ?? []) {
      setUserAccess(prev => ({ ...prev, [workflow.id]: { status: "CHECKING" } }));

      try {
        const access = await checkUserAccess(workflow.id)
        setUserAccess(prev => ({ ...prev, [workflow.id]: { status: "CHECKED", access } }));
      } catch (error) {
        if (error instanceof OAuthError) {
          setUserAccess(prev => ({ ...prev, [workflow.id]: { status: "PENDING" } }));
          setIsCheckingAccess(false);
          return openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
        } else {
          setUserAccess(prev => ({ ...prev, [workflow.id]: { status: "ERROR" } }));
        }
      }
    }

    setIsCheckingAccess(false);
  }, [checkUserAccess, teamWorkflows, openModal, isCheckinggAccess]);

  const grantAccess = useCallback(async (workflowId: string, userId: string) => {
    return await postData(`/workflows/${workflowId}/grant-access/`, { user: userId });
  }, []);

  const handleClickGrant = () => {
    if (nonValidWorkflowIds.length > 0)
      grantAccessToAll()
    else
      closeModal();
  }

  const grantAccessToAll = useCallback(async () => {
    setIsGrantingAccess(true);

    const emailMap = team.members.reduce((acc, user) => {
      acc[user.email] = user.id;
      return acc;
    }, {} as { [email: string]: string });

    const currentAccess: AccessMapping = { ...userAccess };

    for (const workflowId of nonValidWorkflowIds)
      setUserAccess(prev => ({ ...prev, [workflowId]: { status: "PENDING" } }))

    for (const workflowId of nonValidWorkflowIds) {
      setUserAccess(prev => ({ ...prev, [workflowId]: { status: "UPDATING" } }));
      const workflowAccess = currentAccess![workflowId].access!;

      for (const [userEmail, access] of Object.entries(workflowAccess)) {
        if (access === "VALID")
          continue;

        try {
          await grantAccess(workflowId, emailMap[userEmail]);
        } catch (e) {
          // TODO: Handle errors
          // updatedAccess[workflowId] = { status: "ERROR" };
        }
      }
      setUserAccess(prev => ({ ...prev, [workflowId]: { status: "UPDATED" } }));
    }

    setIsGrantingAccess(false);
  }, [grantAccess, userAccess, nonValidWorkflowIds, team.members]);

  useEffect(() => {
    if (hasCheckedSinceUpdate || !teamWorkflows)
      return;

    setHasCheckedSinceUpdate(true);
    checkAccessToAll();

  }, [checkAccessToAll, hasCheckedSinceUpdate, teamWorkflows]);

  useEffect(() => {
    setHasCheckedSinceUpdate(false);
  }, [currentPageNumber]);

  const sortedUsers: Array<TeamUser> = team.members
    .sort((a: TeamUser, b: TeamUser) => {
      if (a.teamRole === "OWNER") return -1;
      if (b.teamRole === "OWNER") return 1;

      const aStr = a.fullName.toLowerCase() + a.email.toLowerCase();
      const bStr = b.fullName.toLowerCase() + b.email.toLowerCase();

      if (aStr < bStr) return -1;
      if (aStr > bStr) return 1;
      return 0;
    });

  if (!data)
    return null;

  return (
    <Modal
      secondaryButtonProps={{ text: "Cancel", onClick: () => closeModal() }}
      primaryButtonProps={{
        text: isCheckinggAccess ? "Checking..." : (nonValidWorkflowIds.length > 0 ? `Grant Access (${nonValidWorkflowIds.length})` : "Done"),
        onClick: handleClickGrant,
        disabled: isCheckinggAccess || isGrantingAccess
      }}
      title="Teams Workflows Access"
      subtitle="Ensure access to the output folder, source, and template files in all workflows."
      size="xl"
    >
      <div className="w-full mt-4 scrollbar-gutter">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-sm font-gilroy text-gray-600 text-left border-b">
              <th className="text-left w-auto">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Workflow
                </div>
              </th>
              <th className="text-left w-[100px]">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Access
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="overflow-y-scroll h-ful max-h-[300px] scrollbar-visible">
        <table className="table-auto w-full">
          <tbody>
            {isValidating && !teamWorkflows && (
              <tr>
                <td>
                  <img src="https://static.portant.co/portant-loading-gray.svg" alt="Loading Logo" className='m-auto w-10 h-10 my-14' />
                </td>
              </tr>
            )}
            {teamWorkflows?.map((workflow: Workflow) =>
              <Fragment key={workflow.id}>
                <tr key={workflow.id}>
                  <td className="w-auto">
                    <div className="flex items-center h-full py-2">
                      <div className="flex justify-center items-center w-10 h-10 mr-4 rounded bg-blue cursor-pointer select-none" style={{ backgroundColor: workflow.color }}>
                        <HeroIcon icon={workflow.icon || "DocumentText"} />
                      </div>
                      <div className="font-semibold text-gray-600 group-hover:text-gray-800 overflow-ellipsis overflow-hidden whitespace-nowrap">
                        {workflow.name}
                      </div>
                    </div>
                  </td>
                  <td className="w-[100px] text-sm">
                    {userAccess?.[workflow?.id]?.status === "CHECKED" &&
                      <div className={`font-semibold font-gilroy cursor-default ${accessSummary[workflow.id].validEntries < accessSummary[workflow.id].totalEntries ? "text-yellow" : "text-green"}`}>
                        {accessSummary[workflow.id].totalEntries === 0
                          ?
                          <Tooltip
                            button={
                              <p className="relative text-gray-400 text-start flex items-baseline gap-0.5 cursor-pointer">
                                No Access
                                <InformationCircleIcon className="h-4 w-4 top-0.5 relative" />
                              </p>
                            }
                            content={
                              <div className="flex flex-col gap-y-2 w-60">
                                <p>Since you don't have access to this file, you can't grant permissions to other team members.</p>
                                <p>Ask the owner of the file to provide permissions first.</p>
                              </div>
                            }
                          />
                          : `${accessSummary[workflow.id].validEntries} / ${accessSummary[workflow.id].totalEntries}`
                        }
                      </div>
                    }
                    {userAccess?.[workflow?.id]?.status === "PENDING" &&
                      <div className="font-semibold font-gilroy text-gray-400">Pending</div>
                    }
                    {userAccess?.[workflow?.id]?.status === "CHECKING" &&
                      <div className="font-semibold font-gilroy text-gray-600">Checking<span className="loading-ellipse" /></div>
                    }
                    {userAccess?.[workflow?.id]?.status === "UPDATING" &&
                      <div className="font-semibold font-gilroy text-gray-600">Updating<span className="loading-ellipse" /></div>
                    }
                    {userAccess?.[workflow?.id]?.status === "UPDATED" &&
                      <div className="font-semibold font-gilroy text-blue">Updated</div>
                    }
                    {userAccess?.[workflow?.id]?.status === "ERROR" &&
                      <div className="font-semibold font-gilroy text-red">Error</div>
                    }
                  </td>
                </tr>
                {(userAccess?.[workflow?.id]?.status === "CHECKED" && accessSummary[workflow.id].validEntries < accessSummary[workflow.id].totalEntries) &&
                  <>
                    {Object.entries(userAccess[workflow.id].access!).filter(([_, access]) => access !== "VALID").map(([userEmail, access]) =>
                      <tr key={workflow.id + userEmail} className="text-xs">
                        <td>
                          <div className="flex items-center gap-1 ml-14 font-semibold font-gray-600">
                            - {userEmail}
                            {access === "INVALID" &&
                              <span className="font-semibold font-gilroy text-red">(Invalid)</span>
                            }
                            {access === "PARTIAL" &&
                              <span className="font-semibold font-gilroy text-yellow">(Partial)</span>
                            }
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                }
              </Fragment>
            )}
            {sortedUsers.length === 0 &&
              <tr className="w-full">
                <td className="flex justify-center items-center py-8" colSpan={5}>
                  <div className="font-semibold text-gray-400">
                    No results
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <Paginator
        enabledNext={enabledNext}
        enabledPrevious={enabledPrevious}
        onPageChange={(newPage) => setCurrentPageNumber(newPage)}
        pageNumber={currentPageNumber}
        numPages={numPages}
        className="mt-auto"
      />
    </Modal>
  )

}

export default SettingsTeamUserModalAccessBatch;
