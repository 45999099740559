import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, DEFAULT_WORKFLOW_MAP } from "../utils";
import OAuthModalMicrosoft from "./OAuthModalMicrosoft";
import ErrorModal from "./ErrorModal";
import BetaLabel from "./BetaLabel";
import MicrosoftPopupModal from "./MicrosoftPopupModal";
import WorkflowModalEdit from "./WorkflowModalEdit";
import { BasicWorkflow, Workflow } from "../interfaces";

interface PickerValue {
  endpoint: string,
  id: string,
  parentReference: { driveId: string },
  sharePoint: {
    uniqueId: string,
    listId: string
  }
}

export interface PickerResponse {
  accessToken: string,
  apiEndpoint: string,
  value: PickerValue[]
  webUrl: string | null;
}

interface Props {
  blockType: string;
  title: string;
  subtitle: string;
  onSelection: (response: PickerResponse, basicWorkflo?: BasicWorkflow) => void;
  isNewWorkflow?: boolean;
}

function WorkflowSelectMicrosoftButton(props: Props) {
  const { openModal, closeModal } = useModal();

  function basicInfoNewFile() {
    const workflow = {
      name: 'New Workflow',
      ...(DEFAULT_WORKFLOW_MAP[props.blockType] ?? { "color": "#cccccc", "icon": "DocumentText" })
    } as Workflow
    openModal(
      <WorkflowModalEdit
        workflow={workflow}
        updateWorkflow={(newWorkflow: BasicWorkflow) => createBlock(newWorkflow)}
        acceptText="Create"
        titleText="Create Workflow"
      />
    );
  }

  async function createBlock(newWorkflow?: BasicWorkflow) {
    try {
      await postData("/microsoft/access-token/", {});

      openModal(<MicrosoftPopupModal />);

      const options = {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
        action: "query",
        accountSwitchEnabled: false,
        advanced: {
          filter: ".xls,.xlsx",
          redirectUri: window.location.origin
        },
        success: (response: PickerResponse) => {
          closeModal();
          if (props.isNewWorkflow && newWorkflow)
            props.onSelection(response, newWorkflow);
          else
            props.onSelection(response);
        },
        cancel: closeModal,
        error: (error: Error) => openModal(<ErrorModal details={error} />)
      }

      // @ts-ignore
      OneDrive.open(options);
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} onClose={handleOpenPicker} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  function handleOpenPicker() {
    if (props.isNewWorkflow)
      basicInfoNewFile();
    else
      createBlock();
  }

  return (
    <button className="flex flex-shrink-0 items-center border-2 border-gray-200 hover:border-blue rounded cursor-pointer px-8 py-4" onClick={handleOpenPicker}>
      <img className="w-12 h-12" src={BLOCK_ICON_MAP[props.blockType]} alt={BLOCK_NAME_MAP[props.blockType]} />
      <div className="flex flex-col items-start ml-4">
        <div className="font-gilroy font-semibold text-lg text-gray-600">
          {props.title}
        </div>
        <div className="font-gilroy font-semibold text-sm text-gray-400">
          {props.subtitle}
        </div>
      </div>
      <BetaLabel />
    </button>
  );
}

export default WorkflowSelectMicrosoftButton;
