import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, UserGroupIcon, XIcon } from "@heroicons/react/outline";
import { OAuthError, putData } from "../backend";
import { WorkflowContext, useModal } from "../contexts";
import { Workflow } from "../interfaces";
import HeroIcon from "./HeroIcon";
import OAuthModal from "./OAuthModal";
import WorkflowModalEdit from "./WorkflowModalEdit";
import WorkflowOverview from "./WorkflowOverview";
import WorkflowDocument from "./WorkflowDocument";
import WorkflowSource from "./WorkflowSource";
import WorkflowOutputs from "./WorkflowOutputs";
import WorkflowAutomationPanel from "./WorkflowAutomationPanel";
import WorkflowEmail from "./WorkflowEmail";
import PayWallModalTeams from "./PayWallModalTeams";
import WorkflowSignatureRequest from "./WorkflowSignatureRequest";
import WorkflowReview from "./WorkflowReview";
import ErrorModal from "./ErrorModal";
import useUser from "../hooks/useUser";
import useWorkflow from "../hooks/useWorkflow";
import WorfklowAutomationsList from "./WorkflowAutomationsList";
import WorkflowModalShare from "./WorkflowModalShare";
import WorkflowSelectModalNew from "./WorkflowSelectModalNew";
import moment from "moment";
import WorkflowDetailNavigation from "./WorkflowDetailNavigation";

type AutoCreateState = "SET" | "PENDING";

interface Props {
  contentOffset: number,
  createNewWorkflow: (stage: "SOURCE" | "DOCUMENT", blockType: string, file?: any) => Promise<Workflow>
}

function WorkflowDetail(props: Props) {
  const history = useHistory();
  const location = useLocation();

  const { url } = useRouteMatch();
  const { contentOffset } = props;

  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const { id } = useParams<{ id: string | undefined }>();

  const { workflow, mutateWorkflow, error } = useWorkflow(id ?? "__latest");

  const [showAutomationPanel, setShowAutomationPanel] = useState(false);
  const [automationOptions, setAutomationOptions] = useState<any>({ ...(workflow?.latestAutomation?.options ?? { mode: "ALL", overwriteOutputs: false }) });

  const [autoCreateState, setAutoCreateState] = useState<AutoCreateState>("SET");

  useEffect(() => {
    if (!workflow)
      return;

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'workflow_id': workflow.id, 'workflow_name': workflow.name, source_type: workflow.source?.sourceType, document_type: workflow.documents[0]?.documentType });
  }, [workflow]);

  useEffect(() => {
    const pageTitle = `${workflow?.name} | Portant`;
    if (workflow?.name && document.title !== pageTitle)
      document.title = pageTitle;

    return (() => { document.title = "Portant" })
  }, [workflow?.name])

  function updateAutoCreate(value: boolean) {
    setAutoCreateState("PENDING");

    const updatedWorkflow = { ...workflow!, autoCreate: value };
    mutateWorkflow(updatedWorkflow, { revalidate: false });

    putData(`/workflows/${workflow?.id}/auto-create/`, { autoCreate: value })
      .then(() => mutateWorkflow(updatedWorkflow, { revalidate: true }))
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
        else
          openModal(<ErrorModal details={error} />)
      })
      .finally(() => setAutoCreateState("SET"));
  }

  function handleClickBack() {
    if (location.pathname === url) {
      history.push("/");
    } else
      history.push(url);
  }

  function updateWorkflow(workflow: Workflow) {
    const updatedAt = moment().toISOString();
    const lastEditedBy = workflow.team?.members?.find(u => u.id === user?.id) ?? workflow.lastEditedBy;

    const optimisticWorkflow = { ...workflow, updatedAt, lastEditedBy };
    mutateWorkflow(optimisticWorkflow, false);

    putData(`/workflows/${workflow.id}/`, workflow)
      .then(mutateWorkflow)
      .catch((err) => openModal(<ErrorModal details={err} />));
  }

  function openEditModal() {
    if (hasPermission(user?.activeTeam, "workflows.edit"))
      openModal(<WorkflowModalEdit workflow={workflow!} updateWorkflow={updateWorkflow} />);
  }

  function openShareModal() {
    if (!user?.subscription?.featureTeams)
      return openModal(<PayWallModalTeams />);

    openModal(<WorkflowModalShare workflow={workflow!} />)
  }

  const updateAutomationOptions = useCallback((options: any) =>
    setAutomationOptions((current: any) => ({ ...current, ...options })), [setAutomationOptions]);

  if (!workflow)
    return (
      <div className='flex flex-col font-sans bg-gray-100' style={{ height: `calc(100vh - ${contentOffset}px)` }}>
        <div className='flex flex-row justify-start py-2 px-4 bg-white shadow z-10 h-14' >
          <ChevronLeftIcon className="h-6 mr-2 my-auto text-gray-600 hover:text-blue cursor-pointer" onClick={() => history.push("/")} />
        </div>
        {!error &&
          <div className='m-auto w-24 h-24'>
            <img src="https://static.portant.co/portant-loading-white.svg" alt="Loading Logo" />
          </div>
        }
        {error &&
          <div className="flex flex-col items-center m-auto">
            {!url.endsWith("/workflow") &&
              <div className="flex flex-col items-center">
                <div className="font-gilroy font-semibold text-gray-600 text-4xl">
                  Workflow not Found
                </div>
                <div className="font-gilroy text-gray-600">
                  This workflow may have been moved or deleted
                </div>
              </div>
            }
            {url.endsWith("/workflow") &&
              <div className="flex flex-col items-center">
                <div className="font-gilroy font-semibold text-gray-600 text-4xl">
                  Welcome to Portant
                </div>
                <div className="font-gilroy text-gray-600">
                  Create your first workflow to get started with Portant
                </div>
              </div>
            }
            <button className="btn btn-blue mt-4" onClick={() => openModal(<WorkflowSelectModalNew onSelection={props.createNewWorkflow} />)}>
              Create New Workflow
            </button>
            <Link to="/" className="underline text-gray-600 text-sm font-semibold mt-4">Return to Home</Link>
          </div>
        }
      </div>
    )

  const workflowContext = { workflow, mutateWorkflow, updateAutomationOptions, updateAutoCreate, automationOptions, showAutomationPanel, setShowAutomationPanel };

  return (
    <WorkflowContext.Provider value={workflowContext}>
      <div className='relative flex flex-col font-sans bg-gray-100' style={{ height: `calc(100vh - ${contentOffset}px)` }}>
        <div className='bg-white shadow z-10 pb-2 lg:pb-0 lg:h-14'>
          <div className="py-2 pb-0 lg:pb-2 px-4 flex flex-row justify-evenly">
            <div className="w-full flex flex-row items-center">
              <button onClick={handleClickBack}>
                <ChevronLeftIcon className="h-6 mr-4 my-auto text-gray-600 hover:text-blue cursor-pointer" />
              </button>
              <button className="flex items-center justify-center w-6 h-6 mr-2 text-white rounded cursor-pointer" style={{ backgroundColor: workflow.color }} onClick={openEditModal}>
                <HeroIcon icon={workflow.icon} className="w-4 h-4" />
              </button>
              <div className="flex items-center gap-2 mr-2">
                <div className="font-semibold font-gilroy truncate max-w-[300px] text-gray-600 cursor-pointer hover:underline" onClick={openEditModal}>
                  {workflow.name}
                </div>
                <div className="relative select-none">
                  {!!workflow.team &&
                    <div className="absolute w-4 h-4 rounded-full shadow bg-green border-white border-2 -right-1.5 -top-1.5">
                      <CheckIcon className="h-3 w-3 text-white" />
                    </div>
                  }
                  <button className="flex items-center bg-blue-50 shadow hover:shadow-inner text-blue font-gilroy font-semibold text-sm hover:underline px-3 py-1 rounded-full" onClick={openShareModal}>
                    <UserGroupIcon className="w-4 h-4 mr-2" /> Share
                  </button>
                </div>
                {!!workflow.team &&
                  <div className="font-semibold text-gray-400 text-xs -top-[11px] relative select-none">
                    {(() => {
                      if (workflow.owner?.id === user?.id)
                        return "Owner"
                      if (hasPermission(workflow.team, "workflows.edit"))
                        return "Editor"
                      if (hasPermission(workflow.team, "workflows.run"))
                        return "User"
                      if (hasPermission(workflow.team, "workflows.view"))
                        return "Viewer"
                    })()}
                  </div>
                }
              </div>
            </div>

            <WorkflowDetailNavigation
              workflow={workflow}
              className="hidden lg:flex -mt-1.5"
            />

            <div className="w-full flex flex-row justify-end items-center gap-8">
              <div className="flex items-center lg:w-80 justify-between pl-1">

                {autoCreateState === "PENDING" &&
                  <div className="flex items-center text-gray-400 text-sm font-gilroy font-semibold select-none">
                    <div className="bg-gray-400 w-3 h-3 rounded-full mr-2" />
                    {workflow.autoCreate ? "Enabling" : "Disabling"}
                    <div className="loading-ellipse" />
                  </div>
                }

                {autoCreateState === "SET" &&
                  <>
                    {(workflow.status === "COMPLETE" && workflow.autoCreate) &&
                      <div className="flex items-center gap-2 text-green text-sm font-gilroy font-semibold select-none cursor-pointer" onClick={() => hasPermission(workflow.team, "workflows.run") && setShowAutomationPanel(true)}>
                        <div className="bg-green w-3 h-3 rounded-full">
                          <div className="bg-green w-3 h-3 rounded-full animate-ping" />
                        </div>
                        Auto-Create is on
                      </div>
                    }
                    {(workflow.status === "COMPLETE" && !workflow.autoCreate && workflow.source?.sourceType === "GOOGLE_FORMS") &&
                      <div className="flex items-center gap-2 text-gray-400 text-sm font-gilroy font-semibold select-none cursor-pointer" onClick={() => hasPermission(workflow.team, "workflows.run") && setShowAutomationPanel(true)}>
                        <div className="bg-gray-400 w-3 h-3 rounded-full" />
                        Auto-Create is off
                      </div>
                    }
                  </>
                }

                <div className="relative ml-auto">
                  <button id="workflow-automate-button" className={`relative flex gap-2 font-gilroy btn ${showAutomationPanel ? "btn-white" : "btn-blue"} w-40 px-0`} onClick={() => setShowAutomationPanel(f => !f)} disabled={!hasPermission(workflow.team, "workflows.run")}>
                    Automate
                    {workflow.status === "INCOMPLETE" &&
                      <div className="absolute bg-yellow border-white shadow border-2 h-4 w-4 rounded-full -left-2 -top-2" />
                    }
                    {(!showAutomationPanel && workflow.status === "COMPLETE" && !workflow.latestAutomation) && // encourage new users to click here
                      <>
                        <div className="absolute bg-green border-white shadow border-2 h-4 w-4 rounded-full -left-2 -top-2" />
                        <div className="absolute bg-green h-4 w-4 rounded-full -left-2 -top-2 animate-ping" />
                      </>
                    }
                    {showAutomationPanel
                      ? <XIcon className="w-4 h-4" />
                      : <ChevronDownIcon className="w-4 h-4" />
                    }
                  </button>
                  <WorkflowAutomationPanel show={showAutomationPanel} />
                </div>

              </div>
            </div>
          </div>
          <div className="flex justify-center items-center lg:hidden">
            <WorkflowDetailNavigation workflow={workflow} size="sm" />
          </div>
        </div>
        <div className="relative w-full h-[calc(100vh-86px)] lg:h-[calc(100vh-56px)]">
          <WorkflowOverview show={url === location.pathname} contentOffset={contentOffset} />
          <WorkflowSource />
          <WorkflowDocument />
          <WorkflowEmail />
          <WorkflowSignatureRequest />
          <WorkflowReview />
          <WorkflowOutputs contentOffset={contentOffset} />
          <WorfklowAutomationsList contentOffset={contentOffset} />
        </div>
      </div>
    </WorkflowContext.Provider >
  );

}

export default WorkflowDetail;
