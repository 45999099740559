import { useContext } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import { CheckIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteData } from "../backend";
import { Email } from "../interfaces";
import BetaLabel from "./BetaLabel";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoadingModal from "./LoadingModal";

function WorkflowOverviewBlockEmail(props: { block: Email, index: number }) {
  const { block } = props;

  const { openModal, closeModal } = useModal();

  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const isValidBlock = block.status === "COMPLETE"

  const history = useHistory();
  const { url } = useRouteMatch();

  function openWorkflowPage() {
    if (workflow.emails.length > 1)
      return history.push(url + "/email#" + (props.index + 1));

    return history.push(url + "/email");
  }

  async function removeBlock(e: React.MouseEvent) {
    e.stopPropagation();

    try {
      openModal(<LoadingModal title="Deleting Block" subtitle="Please wait as this may take a moment." />);
      await deleteData(`/workflows/emails/${block.id}/`);
    } catch (error) {
      console.error(error);
    } finally {
      await mutateWorkflow();
      closeModal();
    }
  }

  return (
    <button id={block.id} className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={openWorkflowPage}>
      {isValidBlock
        ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
        : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
      }
      <img src={BLOCK_ICON_MAP[block.emailType]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
      <div className="flex flex-col w-full overflow-hidden mr-4">
        <div className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
          Send Email
        </div>
        <div className="flex gap-2 items-center font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-500">
          {!isValidBlock &&
            <div className="text-yellow">Incomplete</div>
          }
          {block.emailSubject || "Please configure email"}
        </div>
      </div>
      {block.emailType === "OUTLOOK" && <BetaLabel />}
      {hasPermission(workflow.team, "workflows.edit") &&
        <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={removeBlock}>
          <TrashIcon className="w-4 h-4" />
        </div>
      }
    </button>
  );
}

export default WorkflowOverviewBlockEmail;
