import { WorkflowAutomation } from "../interfaces";

interface Props {
  automation: WorkflowAutomation;
}

function AppHubspotAutomation(props: Props) {
  // const { automation } = props;

  return (
    <div className="h-screen w-screen flex">
      <div className="flex flex-col items-center m-auto">
        {/* <img src="https://static.portant.co/portant-loading-blue.svg" alt="Loading Logo" className='w-32 h-32' /> */}
        <div className="text-blue font-semibold text-center text-sm mt-4 w-[400px]">
          This automation has been started and will be complete shortly. This window can now be closed.
        </div>
      </div>
    </div>
  );
}

export default AppHubspotAutomation;
