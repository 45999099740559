import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, DEFAULT_WORKFLOW_MAP } from "../utils";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import BetaLabel from "./BetaLabel";
import WorkflowModalEdit from "./WorkflowModalEdit";
import { BasicWorkflow, Workflow } from "../interfaces";

interface Props {
  blockType: string;
  title: string;
  subtitle: string;
  onSelection: (blockType: string, file?: any, newWorkflow?: BasicWorkflow) => void;
  pro?: boolean
  beta?: boolean
  isNewWorkflow?: boolean
}

function WorkflowSelectButton(props: Props) {

  const { user } = useUser();
  const { openModal } = useModal();

  function basicInfoNewFile() {
    const workflow = {
      name: 'New Workflow',
      ...(DEFAULT_WORKFLOW_MAP[props.blockType] ?? { "color": "#cccccc", "icon": "DocumentText" })
    } as Workflow
    openModal(
      <WorkflowModalEdit
        workflow={workflow}
        updateWorkflow={(newWorkflow: BasicWorkflow) => createBlock(newWorkflow)}
        acceptText="Create"
        titleText="Create Workflow"
      />
    );
  }

  function createBlock(newWorkflow?: BasicWorkflow) {
    if (props.pro && user?.subscription?.tier === "free")
      openModal(<PayWallModalPro />);
    else
      props.onSelection(props.blockType, newWorkflow);
  }

  function handleSelect() {
    if (props.isNewWorkflow)
      basicInfoNewFile();
    else
      createBlock();
  }

  return (
    <button className="flex flex-shrink-0 items-center border-2 border-gray-200 hover:border-blue rounded cursor-pointer px-8 py-4" onClick={handleSelect}>
      <img className="w-12 h-12" src={BLOCK_ICON_MAP[props.blockType]} alt={BLOCK_NAME_MAP[props.blockType]} />
      <div className="flex flex-col items-start ml-4">
        <div className="font-gilroy font-semibold text-lg text-gray-600">
          {props.title}
        </div>
        <div className="font-gilroy font-semibold text-sm text-gray-400">
          {props.subtitle}
        </div>
      </div>
      {props.pro &&
        <ProLabel />
      }
      {props.beta &&
        <BetaLabel />
      }
    </button>
  );
}

export default WorkflowSelectButton;
