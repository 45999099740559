import { WebhookEvent } from "../interfaces";
import { capitalize, convertCamelCaseToSnakeCase, titleCase } from "../utils";
import moment from "moment-timezone";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useMemo, useState } from "react";

function OutgoingWebhookEventTableRow(props: { event: WebhookEvent }) {
  const { event } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // NOTE: This should have been done on the backend
  const convertedRequestBody = useMemo(() => {
    const convertKeys = (json: any): any => {
      if (json instanceof Object)
        return Object.keys(json).reduce((acc, key) => {
          const newKey = convertCamelCaseToSnakeCase(key);
          acc[newKey] = convertKeys(json[key]);
          return acc;
        }, {} as any);

      if (json instanceof Array)
        return json.map(convertKeys);

      return json;
    };

    return convertKeys(event.requestBody);
  }, [event.requestBody]);

  console.log(JSON.stringify(convertedRequestBody, null, 2));

  return (
    <div className="flex-col text-sm border-b last:border-b-0 w-full">
      <div className="flex items-center py-2 cursor-pointer" onClick={() => setIsExpanded(f => !f)}>
        <div className="w-1/2 truncate flex items-center gap-1">
          <ChevronRightIcon className="w-4 h-4 mr-2 flex-shrink-0" style={{ "rotate": isExpanded ? "90deg" : "0deg" }} />
          {titleCase(event.requestBody?.eventType.replace("_", " "))}
        </div>
        <div className="">
          {event.responseStatus === 200
            ? <div className="bg-green text-white px-1 rounded">Success</div>
            : <div className="bg-red text-white px-1 rounded">Failed</div>
          }
        </div>
        <div className="ml-auto">{capitalize(moment(event.createdAt).fromNow())}</div>
      </div>
      {isExpanded &&
        <div className="bg-gray-100 px-4 py-2 shadow-inner border-b">
          <div className="font-semibold mb-2">Event Data</div>
          <div className="text-xs max-h-[200px] overflow-scroll border bg-white rounded p-2 w-full">
            {JSON.stringify(convertedRequestBody, null, 2)}
          </div>
        </div>
      }
    </div>
  )
}

export default OutgoingWebhookEventTableRow;
