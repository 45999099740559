import { useContext } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import { CheckIcon, DocumentAddIcon, ExclamationCircleIcon, PencilAltIcon, PlusIcon, DocumentDuplicateIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteData } from "../backend";
import { Document } from "../interfaces";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoadingModal from "./LoadingModal";

function WorkflowOverviewBlockDocument(props: { block: Document, index: number }) {
  const { block } = props;

  const { openModal, closeModal } = useModal();

  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const isValidBlock = !!block.file && (!workflow.signatureConfig || workflow.documents.filter(d => d.isSignable).length > 0);

  const history = useHistory();
  const { url } = useRouteMatch();

  function openWorkflowPage() {
    if (workflow.documents.length > 1)
      return history.push(url + "/document#" + (props.index + 1));

    return history.push(url + "/document");
  }

  async function removeBlock(e: React.MouseEvent) {
    e.stopPropagation();

    try {
      openModal(<LoadingModal title="Deleting Block" subtitle="Please wait as this may take a moment." />);
      await deleteData(`/workflows/documents/${block.id}/`);
    } catch (error) {
      console.error(error);
    } finally {
      await mutateWorkflow();
      closeModal();
    }
  }

  if (block.documentType.startsWith("GOOGLE_SHEETS"))
    return (
      <button id={block.id} className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={openWorkflowPage}>
        {isValidBlock
          ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
          : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
        }
        <div className="relative flex-shrink-0 mr-8">
          <img src={BLOCK_ICON_MAP["GOOGLE_SHEETS"]} alt="icon" className="w-12 h-12 flex-shrink-0" />
          {block.documentType === "GOOGLE_SHEETS_MERGE" &&
            <div className="absolute -top-2 -right-1.5 bg-white shadow rounded border text-xs p-1">
              <DocumentDuplicateIcon className="w-4 h-4 " />
            </div>
          }
          {block.documentType === "GOOGLE_SHEETS_APPEND" &&
            <div className="absolute -top-2 -right-1.5 bg-white shadow rounded border text-xs p-1">
              <PlusIcon className="w-4 h-4 " />
            </div>
          }
        </div>
        <div className="flex flex-col w-full overflow-hidden mr-4">
          <div className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
            {block.documentType === "GOOGLE_SHEETS_MERGE" &&
              "Create Document"
            }
            {block.documentType === "GOOGLE_SHEETS_APPEND" &&
              "Append Data to Selected Google Sheets File"
            }
          </div>
          <div className="flex gap-2 items-center font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
            {block.file
              ? <div className="text-gray-500">{block.file.name}</div>
              : <div className="text-yellow">Select Template Document</div>
            }
          </div>
        </div>
        {hasPermission(workflow.team, "workflows.edit") &&
          <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={removeBlock}>
            <TrashIcon className="w-4 h-4" />
          </div>
        }
      </button>
    );

  return (
    <button id={block.id} className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={openWorkflowPage}>
      {isValidBlock
        ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
        : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
      }
      <img src={BLOCK_ICON_MAP[block.documentType]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
      <div className="flex flex-col w-full overflow-hidden mr-4">
        <div className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
          Create Document
        </div>
        <div className="flex gap-2 items-center font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
          {block.file
            ? <div className="text-gray-500">{block.file.name}</div>
            : <div className="text-yellow">Select Template Document</div>
          }

        </div>
      </div>
      <div className="flex flex-col items-start gap-2 ml-auto">
        {block?.createPdfCopy &&
          <div className="text-white text-sm font-semibold flex items-center justify-between relative rounded shadow bg-gray-300 px-2 pt-1">
            PDF
            <DocumentAddIcon className="w-4 h-4 ml-1 mb-1" />
          </div>
        }
        {block?.isSignable &&
          <div className="text-white text-sm font-semibold flex items-center justify-between relative w-24 rounded shadow bg-gray-300 px-2 pt-1">
            Signable
            <PencilAltIcon className="w-4 h-4 ml-1 mb-1" />
          </div>
        }
        {/* TODO: Does there need to be different logic for this? */}
        {false &&
          <div className="text-white text-sm font-semibold flex items-center justify-between relative w-24 rounded shadow bg-gray-300 px-2 pt-1">
            Payable
            <img className="h-4 w-4 ml-1 mb-1 rounded" src=" https://static.portant.co/stripe-logo.png" alt="Stripe" />
          </div>
        }
      </div>
      {hasPermission(workflow.team, "workflows.edit") &&
        <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={removeBlock}>
          <TrashIcon className="w-4 h-4" />
        </div>
      }
    </button>
  );
}

export default WorkflowOverviewBlockDocument;
