import { UserCircleIcon, CreditCardIcon, ChevronLeftIcon, ShieldCheckIcon, UserGroupIcon, GiftIcon, AdjustmentsIcon, FireIcon, KeyIcon } from '@heroicons/react/outline';
import { Switch, Route, Link, useLocation, useHistory } from 'react-router-dom';
import SettingsProfile from './SettingsProfile';
import SettingsBilling from './SettingsBilling';
import SettingsPermissions from './SettingsPermissions';
import SettingsTeam from './SettingsTeam';
import SettingsReferrals from './SettingsReferrals';
import SettingsFormatting from './SettingsFormatting';
import SettingsAchievements from './SettingsAchievements';
import SettingsSolutionPartner from './SettingsSolutionPartner';
import useUser from '../hooks/useUser';
import { useEffect } from 'react';

function SettingsSidebarItem(props: { icon: JSX.Element, label: string, link: string }) {
  const pathname = useLocation().pathname;

  return (
    <Link to={props.link}>
      <div className={`flex items-center p-1 rounded ${pathname === props.link ? "bg-blue-50 text-blue" : "bg-white"}`}>
        {props.icon}
        <div className="ml-4 text-sm font-gilroy font-semibold">{props.label}</div>
      </div>
    </Link>
  );
}

function Settings(props: { contentOffset: number }) {
  const history = useHistory();
  const { user } = useUser();

  const handleBack = () => {
    history.push("/");
  }

  useEffect(() => {
    document.title = "Settings | Portant";
    return (() => { document.title = "Portant" })
  }, []);

  return (
    <div className="flex flex-col h-full px-4 pb-6" style={{ height: `calc(100vh - 100px - ${props.contentOffset}px)` }}>
      <div className="flex items-center my-4">
        <button onClick={handleBack}>
          <ChevronLeftIcon className="h-4 mr-2 text-gray-600 hover:text-blue cursor-pointer" />
        </button>
        <div className="font-semibold">Account Settings</div>
      </div>
      <div className="flex h-full flex-grow ml-4 bg-white rounded-md">
        <div className="flex flex-col gap-4 pt-4 px-2 w-52 h-full border-r border-gray-200 flex-shrink-0">
          <SettingsSidebarItem icon={<UserCircleIcon className="w-6 h-6" />} label="Profile" link="/settings" />
          <SettingsSidebarItem icon={<UserGroupIcon className="w-6 h-6" />} label="Team" link="/settings/team" />
          <SettingsSidebarItem icon={<CreditCardIcon className="w-6 h-6" />} label="Billing" link="/settings/billing" />
          <SettingsSidebarItem icon={<ShieldCheckIcon className="w-6 h-6" />} label="Permissions" link="/settings/permissions" />
          <SettingsSidebarItem icon={<GiftIcon className="w-6 h-6" />} label="Referrals & Credits" link="/settings/referrals" />
          <SettingsSidebarItem icon={<AdjustmentsIcon className="w-6 h-6" />} label="Formatting" link="/settings/formatting" />
          <SettingsSidebarItem icon={<FireIcon className="w-6 h-6" />} label="Achievements" link="/settings/achievements" />
          {(user?.isPartnerAccount || (user?.teams.length ?? 0) > 1) &&
            <SettingsSidebarItem icon={<KeyIcon className="w-6 h-6" />} label="Solution Partner" link="/settings/partner" />
          }
        </div>
        <Switch>
          <Route path="/settings/billing/success" component={SettingsBilling} />
          <Route path="/settings/billing" component={SettingsBilling} />
          <Route path="/settings/permissions" component={SettingsPermissions} />
          <Route path="/settings/team" component={SettingsTeam} />
          <Route path="/settings/referrals" component={SettingsReferrals} />
          <Route path="/settings/formatting" component={SettingsFormatting} />
          <Route path="/settings/achievements" component={SettingsAchievements} />
          {(user?.isPartnerAccount || (user?.teams.length ?? 0) > 1) &&
            <Route path="/settings/partner" component={SettingsSolutionPartner} />
          }
          <Route path="/settings" component={SettingsProfile} />
        </Switch>
      </div>
    </div>
  );
}

export default Settings;
