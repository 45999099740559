import { Transition } from "@headlessui/react";
import { InputHTMLAttributes, useId, useRef, useState } from "react";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  invalid?: boolean;
  enterPrompt?: boolean;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
  isActive?: boolean;
}

type InputState = 'isInvalid' | 'isDisabled' | 'isActive' | 'default';

function TextInputField(props: Props) {
  const { value, onChange, className, onEnter, ...rest } = props;
  const id = useId();
  const ref = useRef<HTMLInputElement>(null);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);

  const isInvalid = props.invalid || (props.required && value.length === 0);

  function handleOnEnter() {
    ref.current?.blur();

    setHasChanges(false);

    setShowSave(true);
    setTimeout(() => setShowSave(false), 2000);

    props.onEnter?.(value);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter")
      handleOnEnter();
  }

  function handleOnChange(e: React.KeyboardEvent<HTMLInputElement>) {
    setHasChanges(true);
    onChange(e.currentTarget.value)
  }

  const inputClasses = {
    isInvalid: 'border-yellow bg-white',
    isDisabled: 'bg-gray-100',
    isActive: 'border-blue bg-white',
    default: 'border-gray-400 bg-white'
  }

  const currentState: InputState = isInvalid ? 'isInvalid' : props.disabled ? 'isDisabled' : props.isActive ? 'isActive' : 'default'

  return (
    <div className={"relative flex flex-col " + className}>
      <input
        ref={ref}
        id={props.id ?? id}
        type='text'
        className={`h-10 px-2 outline-none rounded-md border-2 focus:border-blue-30 ${inputClasses[currentState]} ${props.enterPrompt && (hasChanges || showSave) ? "pr-14" : ""} ${props.className} ${props.label ? 'mt-2' : ''}`}
        value={value}
        onChange={handleOnChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        {...rest}
      />
      {props.label && <label htmlFor={props.id ?? id} className="text-xs font-gilroy font-semibold text-gray-600 bg-white left-2 top-0 px-1 absolute w-auto">
        {props.label}
      </label>}
      {(props.enterPrompt && hasChanges) &&
        <div className={`flex items-center text-xs absolute right-2 ${props.label ? "top-[18px]" : "top-[10px]"} w-12 justify-center border rounded shadow px-1 pt-px bg-blue text-white cursor-pointer`} onClick={handleOnEnter}>
          Enter
        </div>
      }
      <Transition
        show={(props.enterPrompt && (!hasChanges && showSave)) ?? false}
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={`flex items-center text-xs absolute right-2 ${props.label ? "top-[18px]" : "top-[10px]"} w-12 justify-center border rounded shadow px-1 pt-px bg-green text-white cursor-pointer`} onClick={handleOnEnter}>
          Saved
        </div>
      </Transition>
    </div>
  );
};

export default TextInputField;
