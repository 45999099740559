import { Transition } from "@headlessui/react";
import { useContext } from "react";
import { WorkflowContext, useModal } from "../contexts";
import UsagePanel from "./UsagePanel";
import GuidesButton from "./GuidesButton";
import WorkflowSelectModalAdd from "./WorkflowSelectModalAdd";
import WorkflowAutomationPanelSheets from "./WorkflowAutomationPanelSheets";
import WorkflowAutomationPanelForms from "./WorkflowAutomationPanelForms";
import WorkflowAutomationPanelHubspot from "./WorkflowAutomationPanelHubspot";
import WorkflowAutomationPanelXero from "./WorkflowAutomationPanelXero";
import WorkflowAutomationPanelWebhook from "./WorkflowAutomationPanelWebhook";
import WorkflowAutomationPanelExcel from "./WorkflowAutomationPanelExcel";
import useUser from "../hooks/useUser";
import WorkflowAutomationPanelCopper from "./WorkflowAutomationPanelCopper";

interface Props {
  show: boolean;
}

function WorkflowAutomationPanel(props: Props) {
  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  const { openModal } = useModal();

  function handleAddSource() {
    openModal(<WorkflowSelectModalAdd title="Add Workflow Source" subtitle="Add a source to your workflow" showSources {...workflowContext} />);
  }

  function handleAddTemplate() {
    openModal(<WorkflowSelectModalAdd title="Add Workflow Template" subtitle="Add a template to your workflow" showDocuments showEmails {...workflowContext} />);
  }

  return (
    <Transition show={props.show} className="flex flex-col absolute top-14 right-0 p-4 border bg-white text-black drop-shadow rounded w-80 z-20">
      <svg height="10px" width="20px" xmlns="http://www.w3.org/2000/svg" className="absolute text-white right-16 -top-[10px]">
        <polygon points="0,10 10,0 20,10" fill="currentColor" />
        <line x1="0" y1="10" x2="10" y2="0" stroke="lightgray" />
        <line x1="10" y1="0" x2="20" y2="10" stroke="lightgray" />
      </svg>

      {workflow.status !== "COMPLETE" &&
        <div className="flex flex-col items-center gap-2">
          {!workflow.source &&
            <>
              <div className="text-gray-600 font-gilroy font-semibold text-sm text-center w-full whitespace-nowrap">
                Add a source to your workflow to continue
              </div>
              <button className="btn btn-blue" onClick={handleAddSource} disabled={!hasPermission(workflow.team, "workflows.edit")}>
                Add Source
              </button>
            </>
          }
          {workflow.source && !(workflow.documents.length > 0 || workflow.emails.length > 0) &&
            <>
              <div className="text-gray-600 font-gilroy font-semibold text-sm text-center w-full whitespace-nowrap">
                Add a template to your workflow to continue
              </div>
              <button className="btn btn-blue" onClick={handleAddTemplate} disabled={!hasPermission(workflow.team, "workflows.edit")}>
                Add Template
              </button>
            </>
          }
          {workflow.source && (workflow.documents.length > 0 || workflow.emails.length > 0) &&
            <>
              <div className="text-yellow font-semibold text-sm text-center w-full">
                Your workflow must be complete before you can start an automation
              </div>
              <GuidesButton />
            </>
          }
        </div>
      }

      {workflow.status === "COMPLETE" &&
        <>
          {workflow.source?.sourceType === "GOOGLE_SHEETS" &&
            <WorkflowAutomationPanelSheets />
          }
          {workflow.source?.sourceType === "GOOGLE_FORMS" &&
            <WorkflowAutomationPanelForms />
          }
          {workflow.source?.sourceType === "HUBSPOT" &&
            <WorkflowAutomationPanelHubspot />
          }
          {workflow.source?.sourceType === "COPPER" &&
            <WorkflowAutomationPanelCopper />
          }
          {workflow.source?.sourceType === "XERO" &&
            <WorkflowAutomationPanelXero />
          }
          {workflow.source?.sourceType === "MICROSOFT_EXCEL" &&
            <WorkflowAutomationPanelExcel />
          }
          {workflow.source?.sourceType === "WEBHOOK" &&
            <WorkflowAutomationPanelWebhook text="Portant will run this workflow whenever a new webhook event is received." />
          }
          {workflow.source?.sourceType === "TALLY" &&
            <WorkflowAutomationPanelWebhook text="Portant will run this workflow whenever a new response is submitted to Tally." />
          }
          {workflow.source?.sourceType === "GLIDE" &&
            <WorkflowAutomationPanelWebhook text="Portant will run this workflow whenever the selected action occurs in Glide." />
          }
          {workflow.source?.sourceType === "ZAPIER" &&
            <WorkflowAutomationPanelWebhook text="Portant will run this workflow whenever a zap is triggered in Zapier." />
          }
        </>
      }

      <div className="h-px bg-gray-400 w-full my-4" />
      <UsagePanel />

    </Transition>
  );

}

export default WorkflowAutomationPanel;
